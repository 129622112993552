/*general profile styling */
.titleProfile {
  color: #43425d;
  font-size: 1.4rem;
}

.personalDetailsContainer {
  width: 70%;
  max-width: 680px;
}

.tabsSection :global(.ant-tabs-ink-bar) {
  height: 4px;
  background-color: #458edf;
}

.tabsSection :global(.ant-tabs-nav) {
  font-weight: normal;
  color: #4b88df;
  font-size: 1rem;
}

.tabsSection :global(.ant-tabs-nav .ant-tabs-tab-active) {
  font-weight: normal;
  color: #6c6c6c;
}

.tabsSection :global(.ant-tabs-nav .ant-tabs-tab) {
  margin-right: 30%;
  padding: 0.6rem 1rem;
}

/* 2FA Section */

.twoFactorContainer {
  margin-top: 1rem;
}

.twoFactorContainer h2 {
  font-weight: 500;
  font-size: 0.9rem;
  color: #4D4F5C;
  margin-bottom: 1rem;
}

.twoFactorContainer p {
  color: #4D4F5C;
  font-size: 0.8rem;
}

.twoFactorContainer h3 {
  font-weight: 500;
  font-size: 0.83rem;
  color: #4D4F5C;
}

.twoFactorContainer .firstStep,
.twoFactorContainer .secondStep,
.twoFactorContainer .thirdStep {
  margin-bottom: .7rem;
}

.twoFactorContainer .secondStepDisabled {
  opacity: .7;
}

.twoFactorContainer .firstStep :global(.ant-tag) {
  font-size: .7rem;
}

.twoFactorContainer .firstStep h3 :global(.ant-btn) {
  font-size: .7rem;
  height: 22px;
}

.twoFactorContainer .firstStep :global(.ant-tag):first-child,
.twoFactorContainer .firstStep :global(.ant-btn):first-child {
  margin-left: .5rem;
}

.twoFactorContainer .firstStep .recommendedTag {
  color: #1990FF;
  border-color: #1990FF;
  background-color: #1990FF1A;
}

.twoFactorContainer .firstStep .activeTag {
  color: #73B666;
  border-color: #73B666;
  background-color: #73B6661A;
}

.twoFactorContainer .firstStep .defaultTag {
  color: #1990FF;
  border-color: #1990FF;
  background-color: #1990FF1A;
}

.twoFactorContainer .firstStep .makeDefault {
  color: #707070;
  border-color: #707070;
}

.twoFactorContainer .firstStep .makeDefault:hover {
  color: #1990FF;
  border-color: #1990FF;
}

.twoFactorContainer .firstStep :global(.ant-collapse) {
  margin-bottom: .5rem;
  background-color: #ffffff;
  color: #4D4F5C;
  box-shadow: 0px 1px 2px #0000000D;
  border: 1px solid #E3E3E3;
}

.twoFactorContainer .firstStep :global(.ant-collapse>.ant-collapse-item) {
  border-bottom: none;
}

.twoFactorContainer .firstStep :global(.ant-collapse>.ant-collapse-item>.ant-collapse-header) {
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 14px 20px;
}

.twoFactorContainer .firstStep :global(.ant-collapse-content-box) {
  padding: 14px 20px;
  display: flex;
  align-items: center;
}

.twoFactorContainer .firstStep :global(.ant-collapse-item .ant-collapse-expand-icon .ant-collapse-arrow) {
  transform: rotate(90deg);
  color: #D1D1D1;
  margin-right: 0;
}

.twoFactorContainer .firstStep :global(.ant-collapse-item-active .ant-collapse-expand-icon .ant-collapse-arrow) {
  transform: rotate(180deg);
}

.twoFactorContainer .firstStep :global(.ant-radio-wrapper span) {
  font-size: .8rem;
  font-weight: 500;
  color: #4D4F5C;
}

.twoFactorContainer .firstStep :global(.ant-collapse-header-text p) {
  margin: .5rem 0 0 0;
  color: #4D4F5C;
}

.twoFactorContainer :global(.ant-tooltip-disabled-compatible-wrapper) {
  width: auto;
}

.qrContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.qrContainer .imageWrapper {
  width: min-content;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  padding: .8rem;
  margin-right: 1.2rem;
}

.qrContainer .imageWrapper img {
  border: 1px solid #E3E3E3;
  border-radius: 5px;
}

.qrContainer .imageWrapper p {
  color: #4D4F5C;
  font-size: .8rem;
  text-align: center;
  margin: .8rem 0 0 0;
}

.qrContainer .keyWrapper {
  max-width: 400px;
}

.qrContainer .keyWrapper p {
  margin: .8rem 0;
}

.qrContainer .keyWrapper span.copyLabel {
  color: #1990FF;
  text-decoration: underline;
  cursor: pointer;
}

.twoFactorContainer .secondStep .codeWrapper,
.changeDefaultModal .codeWrapper {
  display: flex;
  justify-content: space-between;
}

.twoFactorContainer .secondStep .codeWrapper :global(.ant-input),
.changeDefaultModal .codeWrapper :global(.ant-input) {
  width: 32px;
  margin-right: .3rem;
}

.changeDefaultModal :global(.ant-modal-content) {
  border-radius: 5px;
}

.changeDefaultModal :global(.ant-modal-header) {
  border-bottom: none;
}

.changeDefaultModal :global(.ant-modal-body) {
  padding-top: 0;
}

.errorCode {
  animation: wobble 1s 1;
}

@keyframes wobble {
  25% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.modalBackupCodes :global(.ant-modal-body) {
  padding: 18px 24px 0;
}

.modalBackupCodes :global(.ant-modal-footer) {
  border-top: none;
  padding: 18px 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.modalBackupCodes :global(.ant-modal-footer) div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.modalBackupCodes :global(.ant-modal-footer .ant-btn-primary) {
  width: 150px;
}

.backupCodesContainer h2 {
  font-weight: 500;
  font-size: 0.9rem;
  color: #4D4F5C;
  margin-bottom: 1rem;
}

.backupCodesContainer p {
  color: #4D4F5C;
  font-size: 0.8rem;
}

.backupCodesContainer p.note {
  color: #F84949;
  font-style: italic;
}

.backupCodesContainer .codesWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.backupCodesContainer .codesWrapper span {
  color: #2D71B6;
  font-size: .9rem;
  font-weight: bold;
}

/* Personal Details Section */

.personalDetailsTop,
.personalDetailsBottom,
.companyDetailsBottom {
  display: flex;
}

.personalDetailsBottom {
  justify-content: flex-end;
}

.personalDetailsTop {
  justify-content: flex-end;
  align-items: center;
  border-bottom: 2px solid #f5f5f5;
}

.personalDetailsTop :global(.chooseFileButton) {
  border: 2px solid #f5f5f5;
  background-color: #fff;
  font-weight: 500;
  color: #b3b3b3;
  padding: 0.4rem 2.1rem;
  margin: 0;
}

.personalDetailsTop :global(.chooseFileButton):hover {
  background-color: #f5f5f5;
}

.userInfo {
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.userInfo>p {
  font-size: 0.85rem;
  font-weight: 500;
  width: 100%;
}

.userInfo :global(.ant-input) {
  padding: 0.4rem 1.2rem;
  border: 1px solid #f5f5f5;
  font-weight: 500;
  width: 300px;
  color: #b3b3b3;
}

.confirmInfoChangesBtn,
.confirmPersonalChangesBtn {
  background-color: #4090f7;
  border-radius: 4px;
  padding: 0.4rem 1rem;
  font-weight: normal;
  height: auto;
}

.personalBottomLeft {
  color: #808080;
  padding: 2rem;
  padding-right: 3rem;
  max-width: 300px;
}

.companyBottomLeft {
  color: #808080;
  padding: 2.5rem;
}

.personalBottomLeft,
.companyBottomRight>h3 {
  font-weight: 500;
  font-size: 0.9rem;
  color: #808080;
  padding-bottom: 2rem;
}

.companyBottomRight {
  margin-top: 2.5rem;
}

.contactBlock {
  display: flex;
}

.personalDetailsBottom :global(.ant-input),
.personalDetailsBottom :global(.ant-select-selector) {
  padding: 0.4rem 1.2rem;
  border: 2px solid #f5f5f5;
  font-weight: 500;
  color: #b3b3b3;
}

.companyBottomRight :global(.ant-input),
.companyBottomRight :global(.ant-select-selector) {
  /* width: 260px; */
  padding: 0.4rem 1.2rem;
  border: 2px solid #f5f5f5;
  font-weight: 500;
  color: #b3b3b3;
}

.personalDetailsBottom :global(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
  border: 2px solid #f5f5f5;
  height: 38.78px;
  /* quick fix */
  margin: 0;
}

.datePickerItem {
  width: 100%;
  height: 38.78px;
  border: 2px solid #f5f5f5;
}

.personalBottomRight {
  padding-top: 2rem;
  text-align: right;
  max-width: 300px;
}

/* Change Password */

.updatePassButton :global(.ant-btn) {
  float: right;
}

.securityTabContainer>h2,
.companyDetailsContainer>h2,
.personalDetailsContent>h2 {
  color: #4d4f5c;
  font-size: .9rem;
  border-bottom: 2px solid #f5f5f5;
  padding: 0 1rem 1rem 0;
}

.securityTab {
  display: flex;
  margin-top: 1rem;
}

.securityTab :global(.ant-input-password) {
  /* width: 260px; */
  padding: 0.2rem 1.2rem;
  height: auto;
  border: 2px solid #f5f5f5;
  font-weight: 500;
  color: #b3b3b3;
}

.securityTab>p {
  font-weight: 500;
  color: #808080;
  font-size: 1rem;
  padding: 0 4rem;
}

@media (max-width: 1100px) {

  .personalDetailsTop,
  .personalDetailsBottom,
  .companyDetailsBottom {
    flex-direction: column;
    align-items: center;
  }

  .personalDetailsTop :global(.ant-input) {
    width: 100% !important;
  }

  .companyBottomLeft {
    padding: 1rem;
  }

  .companyBottomRight {
    margin-top: 0;
  }

  .securityTab {
    flex-direction: column;
    align-items: center;
  }

  .personalDetailsContainer {
    width: 100%;
  }
}

@media only screen and (min-width: 1440px) {

  .securityTab :global(.ant-input-password),
  .companyBottomRight :global(.ant-input),
  .companyBottomRight :global(.ant-select-selector) {
    width: 350px;
  }
}

@media only screen and (max-width: 600px) {

  .securityTab :global(.ant-input-password),
  .companyBottomRight :global(.ant-input),
  .companyBottomRight :global(.ant-select-selector) {
    width: 100%;
  }
}

@media only screen and (((min-width: 992px) and (max-width: 1220px)) or (max-width: 780px)) {
  .qrContainer .imageWrapper {
    margin-right: 0;
  }

  .qrContainer {
    flex-direction: column;
  }
}

@media only screen and (((min-width: 992px) and (max-width: 1439px)) or (max-width: 700px)) {
  .twoFactorContainer .secondStep .codeWrapper {
    align-items: center;
    flex-direction: column;
  }

  .twoFactorContainer .secondStep .codeWrapper :global(.ant-btn) {
    margin-top: 1rem;
  }
}

.centerSpinner {
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}