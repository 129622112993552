.targetingBox {
  display: flex;
  width: 60%;
  min-width: 250px;
  gap: 10px;
}

.targetingBox :global(.ant-select-selection-overflow) {
  max-height: 250px;
  overflow: scroll;
  padding-right: .3rem;
}

.clearBtn {
  border-radius: 4px;
  height: 29px;
  color: #1990fa;
  border-color: #1990fa;
}

.infoBtm {
  font-size: 0.8rem;
  padding-top: 10px;
  /* opacity: 0.4; */
}

.infoBtmSubIDs {
  font-size: 0.8rem;
  margin-top: 1rem;
  margin-bottom: .5rem;
}