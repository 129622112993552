.TableTitle> :global(.title) {
  float: left;
}

.TableTitle> :global(.arrows) {
  float: left;
  margin-left: 8px;
  font-size: 0.7em;
  margin-top: -3px;
  color: #bbbbbb;
  cursor: pointer;
}

.TableTitle> :global(.arrows > .active-arrow span) {
  color: #1890ff;
}

.TableTitle> :global(.arrows > .anticon-caret-up) {
  display: block;
}

.TableTitle> :global(.arrows > .anticon-caret-down) {
  display: block;
}

.TableTitle> :global(.title) {
  user-select: none;
}