.generalWrapper {
  width: 100%;
  min-width: 300px;
}
.statusRadioButton {
  flex-direction: row !important;
  align-items: center;
}
.radioButtonLabels {
  width: 75px;
  text-align: center;
}

.autoManualPricing {
  flex-direction: row !important;
  align-items: center;
}

.datepickerStart,
.datepickerEnd {
  width: 50%;
  display: inline-block;
}
.datepickerStart :global(.ant-col.ant-form-item-control) {
  margin-right: 10px;
}
.datepickerStart :global(.ant-picker),
.datepickerEnd :global(.ant-picker) {
  width: 100%;
}

.statusInfo {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.statusInfo > p {
  margin: 0;
  color: #000000d9;
  padding-right: 0.5rem;
}

.campaignBudget {
  display: inline-block;
  width: 60%;
  margin-right: 7%;
}
.campaignBudget input {
  min-height: 32px;
}
.campaignBudgetPeriod {
  display: inline-block;
  width: 33%;
}

/* TRACKING PREVIEW */
.generalInfoHeader,
.generalInfoRows {
  display: block;
  margin-bottom: 0.7rem;
  width: 100%;
}
.trackingInput {
  width: 80%;
  margin-bottom: 10px;
}
.trackingParagraph {
  margin-top: 0.5rem;
  color: darkgrey;
}
.trackingParamSpan {
  color: orangered;
}
.proceedButton {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin-top: 20px;
  border-radius: 4px;
  background-color: #4090f7;
}

.campaignLinkContainer {
  display: flex;
}
.conversionValueBox {
  display: flex;
  margin-left: 20px;
}

.campaignLinkSelect {
  flex: 1;
}
.manualAmountBox {
  width: 110px;
}

@media (max-width: 1100px) {
  .generalWrapper {
    width: 95%;
    margin: 0 auto;
  }
}

/* .campaignTypeInput,
  .accName {
    width: 50%;
    display: inline-block;
  }
  
  .accName :global(.ant-col.ant-form-item-control) {
    margin-right: 10px;
  }
  .campaignTypeInput :global(.ant-form-item-control-input-content) {
    display: flex;
  } */
