.generatorModal {
  width: 100%;
  height: 100vh;
}

.creativeDrawer :global(.ant-drawer-body) {
  background-color: #f8f8f86e;
}

.creativeDrawer :global(.ant-drawer-header) {
  border-bottom: none;
  box-shadow: 0 0 10px 0 #0000000d, 0 0 1px 0 #0000000d;
}

.creativeGridContainer {
  /* height: calc(100vh - 103px); */
  height: 100%;
  /* overflow: hidden; */
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1150px) {
  .creativeGridContainer {
    height: auto;
    flex-direction: column;
  }
}