.formLabels {
  text-align: left;
  font: bolder 12px/20px;
  letter-spacing: 0px;
  color: #b3b3b3;
  font-weight: 900;
  margin-bottom: 0px;
  margin-top: 20px;
}

.formTitles {
  text-align: left;
  font: bolder 18px/13px;
  letter-spacing: 0px;
  color: #4d4f5c;
  opacity: 1;
}

.gutter-col {
  padding: 10px;
}
.imageUploaderModal .fileContainer {
  margin: 0;
  box-shadow: none;
  border-radius: 5px;
  padding: 0;
}
.imageUploaderModal .fileContainer img {
  display: none;
}
.imagePreviewModal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 90px;
  border-radius: 50%;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.imagePreviewModal img {
  width: 130px;
  height: 130px;
  object-fit: cover;
}
.removeImageButton {
  opacity: 0.1;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  transition: opacity 0.3s;
}
.removeImageButton:hover {
  opacity: 1;
}
.removeImageButton img {
  cursor: pointer;
  position: absolute;
  margin-left: 100px;
}
