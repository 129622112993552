.campaignTypeModal h1 {
  font-size: 1.3rem;
  color: rgb(89, 89, 89);
  margin: 0;
}
.campaignTypeModal h3 {
  margin: 0;
}

.campaignTypeModalHeader {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.campaignTypesDivs {
  padding: 20px;
  text-align: center;
  min-height: 430px;
}
.campaignTypeSection {
  position: relative;
  height: 100%;
  border: 1pt solid #4090f7;
  border-radius: 7px;
  padding: 20px 10px;
  position: relative;
  cursor: pointer;
}

.campaignTypeSection:hover :global(.ant-btn) {
  color: #fff;
  border-color: #40a9ff;
  background: #40a9ff;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.adTypeHightlight {
  position: absolute;
  top: -15px;
  right: -30px;
  transform: rotate(45deg);
}

.adTypeHightlight span:nth-child(1) {
  color: #faad14;
  transform: translateY(12px) rotate(-20deg);
}
.adTypeHightlight span:nth-child(2) {
  color: #faad14;
}
.adTypeHightlight span:nth-child(3) {
  color: #faad14;
  transform: translateY(12px) rotate(20deg);
}

.campaignTypeSection h2 {
  color: rgb(72, 72, 72);
  font-weight: 600;
  margin-bottom: 0;
  font-size: 1.2rem;
}

.campaignTypeSection img {
  width: 70%;
  height: 120px;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.5s;
}
.campaignTypeSection h3 {
  font-size: 0.9rem;
  font-weight: 600;
  height: 40px;
}
.campaignTypesDesc {
  text-align: left;
  padding-top: 0.3rem;
  font: Bold 18px/13px;
  color: #2f6fb6;
  margin: 20px 0px 10px 0px;
  text-align: center;
}
.campaignTypesSelectBtn {
  width: 220px;
  height: 35px;
  background: #4090f7 0% 0% no-repeat padding-box;
  border-radius: 5px;
}
.loadingSelectCampaign {
  min-height: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formatDescription {
  display: flex;
  padding: 0.8rem 0.8rem 0rem 0.8rem;
}
.formatDescription > span {
  display: inline-block;
  margin-top: 0.5rem;
  height: 8px;
  min-width: 8px;
  border-radius: 50%;
  background-color: #faad14;
}
.formatDescription > p {
  font-size: 0.85rem;
  color: rgb(141, 141, 141);
  padding: 0rem 0.5rem;
  text-align: left;
  height: 100px;
}

@media screen and (max-width: 1366px) {
  .campaignTypeSection {
    padding: 20px 10px;
  }
}

@media screen and (max-width: 991px) {
  .campaignTypeSection {
    display: flex;
  }
  .campaignTypesDivs {
    padding: 20px;
    text-align: center;
    min-height: 180px;
  }
  .campaignTypeSection p {
    height: auto;
  }
  .campaignTypeSection h3 {
    height: auto;
    margin: 0;
  }
  .campaignTypeSection > div:first-child {
    min-width: 180px;
  }
}

@media screen and (min-width: 721px) and (max-width: 991px) {
  .formatDescription > p {
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 720px) {
  .campaignTypeSection {
    flex-direction: column;
  }
  .formatDescription {
    padding: 0.8rem 1rem 0rem 1rem;
  }
}

@media screen and (min-width: 992px) {
  .formatDescription > p {
    font-size: 0.85rem;
    padding: 0rem 0.55rem;
    text-align: justify;
    text-justify: inter-word;
  }
}