.headingActions {
  display: flex;
  margin-bottom: 30px;
}

.headingActions>div {
  margin-right: 20px;
}

.columnGroupContainer {
  width: 500px;
}

.columnGroupContainer :global(.ant-checkbox-inner) {
  width: 20px;
  height: 20px;
}

.searchInput {
  width: 250px;
}

.campaignsTable table thead th {
  background-color: #fff;
  color: #707070;
  font-size: 0.9rem;
}

.campaignsTable table tbody {
  font-size: 0.85rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #808080 !important;
}

.campaignsTable :global(.ant-table-row) {
  background-color: #fff;
}

.campaignsTable :global(.ant-table-footer) {
  background-color: #fff;
}

.campaignName {
  /* text-align: left; */
  /* font-weight: 500; */
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
  color: #0071bd;
  font-weight: 600;
  cursor: pointer;
  margin-left: 8px;
}

.campaignTypeAndName {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 4px;
}

.campaignId span {
  color: #4D4F5C;
  font-weight: 400;
}

/* .statusColumn, */
/* .status, */
.campaignNameWrapper,
.campaignType {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5b49a7;
}

.status {
  background-color: #d1d1d11a;
  border: 1px solid #d1d1d1;
  color: #d1d1d1;
}

.campaignType {
  display: list-item;
  list-style-type: none;
  list-style-position: inside;
  font-weight: bold;
  font-size: 1rem;
}

.campaignType span {
  position: relative;
  left: -5px;
  font-size: 0.85rem;
}

.campaignNameWrapper {
  justify-content: flex-start;
}

.campaignsTable .campaignNameColumn {
  cursor: pointer;
}

.status {
  text-transform: capitalize;
  min-width: 90px;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  margin-right: 0.5rem;
}

.statusactive {
  background-color: #02875b1a;
  border-color: #02875b;
  color: #02875b;
}

.statuspaused {
  background-color: #d1d1d11a;
  border-color: #d1d1d1;
  color: #d1d1d1;
}

.statussuspended {
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}

.statuspending {
  background-color: #faad141a;
  border-color: #faad14;
  color: #faad14;
}

.statusready {
  background-color: #02875b1a;
  border: 1px solid #02875b;
  color: #02875b;
}

.statusimplementing {
  background-color: #1990ff1a;
  border: 1px solid #1990ff;
  color: #1990ff;
}

.statusdisabled {
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}

.campaignTypePushCpa {
  color: #32b66b;
}

.campaignTypePushCpc {
  color: #d95c5c;
}

.campaignTypeNativeCpc {
  color: #3067ce;
}

.campaignTypeSmartlinkCpa {
  color: #781edf;
}

.nameInitials {
  width: 2.3em;
  height: 2.3em;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(85, 85, 85);
  padding: 5px;
  margin-right: 5px;
  text-align: center;
}

.campaignsTable :global(.ant-table-tbody > tr > td) {
  padding: 18px 12px;
}

.campaignsTable :global(.ant-table-tbody > tr > td:last-child) {
  padding: 6px 12px;
}

.channelName,
.companyName,
.dateCreated {
  text-align: left;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
}

.channelName {
  color: #0071bd;
  font-weight: 600;
  cursor: pointer;
}

/* .channelName {
  width: 200px;
} */
.campaignId {
  font-size: 0.8rem;
  color: #0071bd;
  font-weight: 600;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions>button {
  cursor: pointer;
  margin-right: 6px;
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
}

.actions>span {
  margin-right: 6px;
}

.actions button:last-child {
  margin-right: 0;
}

.actions :global(.ant-popover-inner-content) {
  padding: 5px 8px !important;
}

/* CAMPAIGNS TITLE AND FILTERS AREA */

.titleContent {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  color: #43425d;
}

.titleContent>h3 {
  font-size: 1.4rem;
  color: #43425d;
  padding-right: 0.9rem;
  margin: 0;
  font-weight: 500;
}

.newCampaignBtn {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  border-radius: 4px;
  background-color: #4090f7;
}

/* .newCampaignBtn { */
/* display: flex;
  align-items: center; */
/* padding: 1.2rem 0.9rem; */
/* background-color: #4090f7; */
/* border-radius: 6px; */
/* } */

.filtersContainer {
  background-color: #fafafa;
  padding: 0.5rem 1rem 1.5rem 1rem;
}

.dateRadioSelect {
  font-size: 0.9rem;
}

.campaignsTable :global(.ant-radio-group-large .ant-radio-button-wrapper) {
  color: #2f6fb6;
  font-size: inherit;
  background-color: transparent;
}

.campaignsTable :global(.ant-radio-group-large .ant-radio-button-wrapper-checked) {
  font-weight: 550;
  border: 1px solid !important;
  border-color: rgba(47, 111, 182, 0.4) !important;
  border-radius: 6px;
  background-color: rgba(47, 111, 182, 0.03);
  line-height: 28px;
  height: 30px;
}

.campaignsTable :global(.ant-radio-group-large .ant-radio-button-wrapper) {
  border: none;
}

.campaignsTable :global(.ant-radio-group-large .ant-radio-button-wrapper::before) {
  display: none;
}

.dateRadioSelect {
  margin-bottom: 0.5rem;
}

/* .filtersContainerBottom {
  display: flex;
} */
.filtersContainerBottom :global(.Select) {
  width: 200px;
  /* height: 32px; */
  margin-right: 1rem;
  border-radius: 0;
}

.filtersContainerBottom :global(.Select-control) {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  height: 31px;
  z-index: 10;
  overflow: auto;
}

.filtersContainerBottom :global(.has-value .Select-control > *:last-child) {
  display: inline-block;
  margin-left: 0rem;
}

.filtersContainerBottom :global(.Select--multi .Select-clear-zone) {
  display: inline-block;
  margin-left: 5.5rem;
}

.filtersContainerBottom :global(.Select-value .Select-value-label) {
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filtersContainerBottom :global(.Select-input) {
  height: 30px;
}

.filtersContainerBottom :global(.Select-placeholder) {
  line-height: 31px;
}

.filtersContainerBottom :global(.Select-value) {
  width: 100%;
  max-width: 185px;
  white-space: nowrap;
  overflow: hidden;
}

.rangeDatePicker {
  height: 32px;
  margin-right: 1rem;
}

.inputTextSearch {
  max-width: 400px;
  width: 50%;
  min-width: 150px;
  margin-right: 1rem;
}

.selectStatuses,
.selectFormats {
  height: 32px;
  min-width: 120px;
  margin-right: 1rem;
}

.applyFiltersBtn {
  margin-top: 0.7rem;
  background-color: #3d8af8;
  /* width: 100%; */
}

/* .campaignsTable .selectStatuses :global(.ant-select-selector),
.campaignsTable .selectFormats :global(.ant-select-selector) {
  height: 100%;
} */

.extActionGroup {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 1rem;
}

.extActionGroup .extActionRightGroup :global(.ant-btn) {
  color: #464646;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
}

.extActionGroup .extActionRightGroup :global(.ant-btn):hover {
  color: #40a9ff;
  background: #fff;
  border-color: #40a9ff;
}

.extActionGroup .extActionRightGroup :global(.ant-btn):not(:last-child) {
  margin-right: 0.5rem;
}

.budgetColContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.budgetColContainer button {
  margin-left: 0.5rem;
}

.budgetColContainer :global(.ant-btn-icon-only) {
  height: 22px;
  width: 22px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.budgetColContainer :global(.ant-btn > .anticon) {
  line-height: 0;
}

.budgetColContainer :global(.ant-btn-icon-only > *) {
  font-size: 13px;
}

.cpcColContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cpcColContainer button {
  margin-left: 0.5rem;
}

.cpcColContainer :global(.ant-btn-icon-only) {
  height: 22px;
  width: 22px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cpcColContainer :global(.ant-btn > .anticon) {
  line-height: 0;
}

.cpcColContainer :global(.ant-btn-icon-only > *) {
  font-size: 13px;
}

.antTableContent :global(.ant-table-cell) {
  color: rgb(115, 115, 115);
}

.campaignsTable :global(.ant-table-footer > ul) {
  width: 100%;
}

@media (max-width: 1000px) {
  .filtersContainerBottom {
    flex-direction: column;
    align-items: center;
  }

  .inputTextSearch,
  .selectStatuses,
  .rangeDatePicker,
  .selectFormats {
    width: 100%;
    max-width: none;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .applyFiltersBtn {
    max-width: 400px;
  }

  .campaignsTable :global(.ant-radio-group-large .ant-radio-button-wrapper) {
    display: none;
  }

  .extActionGroup {
    flex-direction: column;
  }

  .titleContent {
    flex-direction: column;
    align-items: flex-start;
  }

  .columnsBtn,
  .reloadBtn {
    margin-bottom: 0.4rem;
  }
}

@media (min-width: 750px) {
  .campaignsTable :global(.ant-table-footer > ul > li:first-child) {
    background-color: #fff;
    display: inline-block;
    position: absolute;
    left: 0px;

  }
}

.pricingColumn {
  display: flex;
  justify-content: center;
}

.pricingColumn .confirmButtonDisabled {
  position: relative;
  top: 4px;
  right: 0;
  margin-left: 1rem;
}

.pricingColumn .confirmButtonGreen {
  position: relative;
  top: 4px;
  right: 0;
  margin-left: 1rem;
  color: #73B566;
  border: 1px solid #73B566;
  background-color: #73B5661a;
}

.priceUpdateContainer {
  display: flex;
}

.priceUpdateContainer :global(.ant-btn) {
  padding: 0;
  height: 100%;
  width: 33px;
  border: 1px solid #EEEEEE;
  color: #4D4F5C;
  font-size: 13px;
  box-shadow: none;
  border-radius: 0 5px 5px 0;
}

.priceUpdateContainer :global(.ant-btn:first-child) {
  border-radius: 5px 0 0 5px;
}

.mainPriceBox {
  /* padding: 0.3rem; */
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
}

.mainPriceBox * {
  box-shadow: none;
}

.mainPriceBox :global(.ant-input-number) {
  border: none;
  border-radius: 0;
}

.mainPriceBox :global(.ant-input-number:focus) {
  border: none;
  box-shadow: none;
}

.mainPriceBox :global(.ant-input-number-input-wrap) input {
  padding: 0;
  text-align: center;
  color: #43425d;
}

.mainPriceBox :global(.ant-input-number-handler-wrap) {
  position: relative;
  display: none;
}

.budgetCapContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.budgetCapContainer .status {
  margin-bottom: .3rem;
}

.budgetCapContainer :global(.ant-btn-icon-only) {
  width: 26px;
  height: 26px;
  margin-right: .5rem;
}