.containerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;
}

.containerHeader h2 {
    margin: 0;
    font-weight: 600;
    color: #4D4F5C;
}

.containerHeader .showUnread {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.containerHeader .showUnread span {
    color: #4D4F5C;
}

.barNotification {
    display: inline;
}

.barNotification>p {
    /* display: inline; */
    font-size: 14px;
    color: #4D4F5C;
}

.barNotificationList {
    width: 496px;
    border-radius: 8px;
    border: transparent;
    background: white;
    box-shadow: 0px 2px 15px #0000000D;
}

.virtualListWrapper :global(.rc-virtual-list-holder) {
    border-radius: 0 0 8px 8px;
}

.barNotificationList :global(.ant-list-footer) {
    padding: 0;
}

.barNotificationList :global(.ant-list-bordered .ant-list-header) {
    border-radius: 8px 8px 0 0;
    box-shadow: 0px 2px 15px #0000000D;
}

.barNotificationList .unReadlistItem {
    width: 496px;
    background-color: rgba(25, 144, 255, 0.05);
}

.barNotificationList .notificationTitle {
    display: flex;
    justify-content: space-between;
    color: #4D4F5C;
}

.barNotificationList .notificationTitle :global(.ant-radio-checked .ant-radio-inner::after) {
    transform: scale(1);
}

.barNotificationList .notificationTitle :global(label.ant-radio-wrapper) {
    margin: 0;
}

.barNotificationList .notificationTitle :global(.ant-radio-inner::after) {
    background-color: #FFAA2D;
}

.barNotificationList .notificationTitle :global(.ant-radio-checked:after) {
    border: 1px solid transparent !important;
}

.barNotificationList .notificationTitle :global(.ant-radio-input:focus + .ant-radio-inner) {
    box-shadow: none;
}

.barNotificationList .notificationTitle :global(.ant-radio-inner) {
    background-color: #F0F0F0;
    border-color: #F0F0F0;
}

.barNotificationList .notificationTitle div {
    display: flex;
    align-items: center;
}

.barNotificationList .notificationTitle .notificationIcon {
    margin-right: .4rem;
    height: 1rem;
}

.barNotificationList .notificationTitle h3 {
    font-size: .95rem;
    font-weight: 600;
    color: #4D4F5C;
    vertical-align: middle;
    margin: 0;
}

.barNotificationList .notificationDescription {
    margin-top: 10px;
    overflow: hidden;
    position: relative;
}

.barNotificationList .notificationDate {
    margin-top: .5rem;
    font-size: 12px;
    color: #989898;
}

.notificationDescription .notificationExpandLinkWrapperRead {
    padding-left: 5px;
    margin: 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
    cursor: pointer;
}

.notificationDescription .notificationExpandLinkWrapperUnread {
    padding-left: 5px;
    margin: 0;
    background-color: rgba(25, 144, 255, 0.05);
    cursor: pointer;
}

.notificationExpandLink {
    color: #4D4F5C;
    text-decoration: underline;
}

.barNotificationItemModal :global(.ant-modal-content) {
    border-radius: 5px;
}

.barNotificationItemModal :global(.ant-modal-header) {
    padding: 20px 30px 10px;
    border-bottom: 0px solid #f0f0f0;
    border-radius: 5px 5px 0 0;
}

.barNotificationItemModal :global(.ant-modal-body) {
    padding: 10px 30px 10px;
}

.barNotificationItemModal :global(.ant-modal-footer) {
    border-top: 0px solid #f0f0f0;
    padding: 10px 30px 20px;
}

.barNotificationItemModal .barNotificationItemModalTitle {
    display: flex;
    align-items: center;
}

.barNotificationItemModal .barNotificationItemModalTitle h3 {
    display: inline;
    font-size: 1rem;
    font-weight: 600;
    color: #4D4F5C;
    vertical-align: middle;
    margin: 0;
}

.barNotificationItemModalIcon {
    vertical-align: middle;
    margin-right: 0.4rem;
    height: 1rem;
}