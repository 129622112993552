.formContainer {
  height: calc(87vh);
  overflow: hidden;
  background-color: #f8f8f86e;
}

.formContainerHead .sideWidth {
  width: 100%;
  white-space: nowrap;
  color: #4d4f5c;
}

.formContainerHead {
  font-size: 1rem;
  font-weight: 400;
  background-color: #fff;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-bottom: 1px solid rgb(233, 233, 233);
}
.formContainerBody {
  display: flex;
  height: calc(87vh - 120px);
}
.formContainerFoot {
  background-color: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-top: 1px solid rgb(233, 233, 233);
}

.cloneMessage {
  font-size: 12px;
  border: 1px solid #faad14;
  color: #faad14;
  background-color: #faad141a;
  /* height: 36px; */
  /* max-height: 72px; */
  /* line-height: 36px; */
  border-radius: 5px;
  padding: 4px 14px;
  min-width: 0;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 5px;
}

.formContainer :global(.ant-select),
.formContainer :global(.ant-radio-button-wrapper),
.formContainer :global(.ant-btn),
.formContainer :global(.ant-input),
.formContainer :global(.ant-input-number),
.formContainer :global(.ant-form-item-explain-error),
:global(.ant-tooltip),
.formContainer :global(.ant-form-item-label > label),
.formContainer :global(.ant-select-item-option-content),
.formContainer :global(.ant-checkbox-wrapper) {
  font-size: 12px;
}

.sideWidth {
  position: relative;
  padding: 0 20px;
  flex: 1;
  max-width: 240px;
  min-width: 150px;
}

.leaveCreatingBtn {
  width: 100%;
  color: #1990ff;
  border-color: #1990ff;
  border-radius: 5px;
  min-width: 145px;
}

.saveBtn {
  background-color: #1990ff;
  border-color: #1990ff;
  border-radius: 5px;
  width: 100%;
}

.leaveCreatingBtnContainer {
  position: absolute;
  width: calc(100% - 40px);
  height: 100%;
  right: 100%;
}

@media screen and (max-width: 980px) {
  .sideWidth {
    flex: 3;
  }
}
@media screen and (max-width: 980px) {
  .sideWidth {
    flex: 3;
  }
}
