.radioButtonSelection {
  margin: 15px 0;
}

.radioButtonSelection :global(.ant-radio-wrapper) {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.radioButtonSelection :global(.ant-radio-wrapper) > span:last-child {
  padding-top: 4px;
}

.radioSuperFresh {
  margin-left: 0.3rem;
  border: 1px solid #faad2d;
  font-weight: 500;
  padding: 0.15rem 0.3rem;
  border-radius: 4px;
  color: #faad2d;
  font-size: 0.7rem;
  background-color: #faab2d0f;
}

.freshnessCheck {
  padding: 0 5px 5px 0;
}
