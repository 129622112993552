.container {
  position: relative;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.containerGroup > img {
  margin-top: 40px;
  height: 90%;
  width: 90%;
}

.title {
  font: normal normal bold 160px "Montserrat", sans-serif;
  margin-bottom: 0;
  color: #ffffff;
}

.containerGroup > p {
  color: #ffffff;
}

.goBack {
  /* background: #FFAA2D; */
  border-color: #ffaa2d;
  background-color: #ffaa2d;
  /* box-shadow: 0px 2px 8px #0000001A; */
  border-radius: 15px;
  margin-top: 20px;
  height: 45px;
  /* height: 3.2142857142857144em; */
  padding: 0 70px 0px 70px;
  font: normal normal 600 18px/26px "Montserrat", sans-serif;
}

.paragraphEmptySpaceship {
  font: normal normal bold 26px/26px "Montserrat", sans-serif;
}

.paragraphNotExistPage {
  /* margin-top: 40px; */
  font: normal normal normal 18px/26px "Montserrat", sans-serif;
}

@media (max-width: 640px) {
  .title {
    font-size: 160px;
  }
  .paragraphEmptySpaceship {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 120px;
  }
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
