.radioButtonSelection {
  margin: 15px 0;
}

.radioButtonSelection :global(.ant-radio-wrapper) {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.radioButtonSelection :global(.ant-radio-wrapper) > span:last-child {
  padding-top: 4px;
}

.specificItemSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.opTargeting {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.8rem;
  padding-bottom: 10px;
}

.additionalTargetingSubtitle {
  font-weight: 600;
  font-size: 0.8rem;
}
