.container {
  position: relative;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerGroup {
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
}

.containerGroup > img {
  margin-top: 10px;
  max-width: 200px;
}

.containerGroup > .title {
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #ffffff;
}

.containerGroup > p {
  text-align: center;
  font-size: 1rem;
  color: #ffffff;
}

.goBack {
  /* background: #FFAA2D; */
  border-color: #ffaa2d;
  background: #ffaa2d 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 2px 8px #0000001A; */
  border-radius: 3px;
  margin-top: 20px;
  height: 45px;
  /* height: 3.2142857142857144em; */
  padding: 0 70px 0px 70px;
  font: normal normal normal 18px/26px 'Source Sans Pro', sans-serif;
}

.paragraphEmptySpaceship {
  font: normal normal 600 25px/26px 'Source Sans Pro', sans-serif;
}

.paragraphNotExistPage {
  margin-top: 40px;
  font: normal normal normal 16px/26px 'Source Sans Pro', sans-serif;
}
