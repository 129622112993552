.budgetModalWrapper :global(.ant-modal-content) {
    border-radius: 5px;
}

.budgetModalWrapper :global(.ant-modal-header) {
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    padding: 20px 30px 0 30px;
}

.budgetModalWrapper :global(.ant-modal-close-x) {
    font-size: 12px;
}

.budgetModalWrapper :global(.ant-modal-body) {
    padding: 20px 30px;
}

.budgetModalWrapper :global(.ant-modal-body) p {
    margin: 0;
}

.actionButtons {
    margin: 0;
}

.actionButtons :global(.ant-form-item-control-input-content){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.actionButtons button:first-child {
    width: 35%;
}

.actionButtons button:nth-child(2) {
    width: 55%;
}

.inputDollar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.inputDollar :global(.ant-row){
    margin-bottom: 1rem;
    width: 100%;
}

.inputDollar :global(.ant-input-number){
    border-radius: 2px 0 0 2px;
}

.inputDollar .dollarBox {
    height: 32px;
    width: 32px;
    margin-top: 1.89rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FAFAFA;
    color: #D1D1D1;
    border: 1px solid #D1D1D1;
    border-left: none;
    border-radius: 0 2px 2px 0;
}