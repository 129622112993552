.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.3rem;
}

.titleContainer > h4 {
  color: #43425d;
  font-size: 1.4rem;
  margin: 0;
}

.dashBottomContent {
  display: flex;
}

/* CONTACT INFO */
.contactInfoSpin {
  grid-column: span 2;
}
.contactInfoContainer {
  position: relative;
  padding: 0.7rem;
  display: flex;
  background-color: #465161;
}
.contactInfoLeft {
  display: flex;
  align-items: center;
}
.contactIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0.8;
}
.contactIcon:hover {
  opacity: 1;
}

.contactInfoLeft > div {
  height: 100%;
  display: flex;
  align-items: flex-end;
  width: 60px;
  justify-content: space-between;
  margin-right: 1rem;
  padding-bottom: 1rem;
}
.contactInfoLeft > img {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 1rem;
  padding: 1rem;
}

.contactInfoRight {
  color: #fff;
  padding: 1rem;
  font-size: 0.9rem;
}
.contactInfoRight > div {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #fff;
}

.contactInfoRight > div :global(span) {
  margin-left: 1rem;
}
.contactInfoRight > div :global(span):last-child {
  margin-left: 0.2rem;
}
.contactInfoRight > p:first-child {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0;
  padding-bottom: 0.2rem;
}
.closeBtn {
  position: absolute;
  color: #fff;
  padding-top: 0.3rem;
  padding-right: 0.5rem;
  font-size: 1.2rem;
  top: 0;
  right: 0;
  cursor: pointer;
}
.closeMessage {
  margin: 0;
  color: #ffffffde;
  margin: 0 auto;
  text-decoration: underline;
  cursor: pointer;
}

/* EARNINGS MAP */
.earningsMapContainer {
  grid-row: span 3;
}
.earningsMapContainer .earningsMapHeader {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  font-size: 1.1rem;
  color: #4d4f5c;
}
.earningsMapHeader > span {
  font-size: 0.9rem;
  color: #012d6d;
  cursor: pointer;
}

.earningsMapContainer :global(.jvectormap-zoomin) {
  background: #3234408c !important;
  width: 17px;
  height: 17px;
}
.earningsMapContainer :global(.jvectormap-zoomout) {
  background: #3234408c !important;
  width: 17px;
  height: 17px;
}
.mapEarningsList {
  display: inline-block;
  background: #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

/* CHANNELS TABLE CONTAINER */
.channelsTableContainer {
  grid-column: span 2;
  grid-row: span 3;
}
.channelsTableContainer :global(.ant-table-thead tr th) {
  color: #a3a6b4;
  font-weight: bold;
}
.channelsTableContainer > h4 {
  color: #4d4f5c;
  font-size: 1.3rem;
  padding: 1rem 0 0.5rem 0;
}

/* INSIGHTS */
.insightsContainer {
  border: 1px solid #dedede;
  padding: 1rem;
}
.insightsContainer > h4 {
  color: #2d71b6;
  font-weight: bold;
  font-size: 1.2rem;
}
.insightsList > p {
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 0.9rem;
}
.insightsDot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f9ae3b;
  margin-right: 0.5rem;
}

/* USERS BY DEVICE PIE CHART */
.usersDevicePieContainer {
  margin-top: 1rem;
  grid-column: span 1;
}
.usersDevicePieContainer h4 {
  font-size: 1.1rem;
  color: #4d4f5c;
  margin: 0;
}
.bottomDevicesStats {
  display: flex;
  justify-content: space-around;
}
.bottomDevicesStats > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottomDevicesStats > div > p {
  margin: 0;
  padding: 0.2rem 0 0 0;
  font-size: 0.8rem;
  color: #a6a6a6;
}
.bottomDevicesStats > div > span {
  font-size: 1.1rem;
  font-weight: 600;
}

/*  NEWS BLOG */
.newsBlogContainer {
  grid-column: span 2;
  grid-row: span 2;
}
.blogCard {
  display: flex;
  cursor: pointer;
  margin-top: 1rem;
  padding-right: 1rem;
  box-shadow: 0px 2px 6px #00000012;
}
.blogCard > div:last-child {
  width: 75%;
}
.blogCard > div:first-child {
  width: 25%;
  min-width: 150px;
  max-width: 190px;
}
.blogCard:hover {
  opacity: 0.85;
  transition: opacity 0.2s;
}
.blogCard p {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.blogDesc {
  font-size: 0.8rem;
}
.blogTitle {
  font-weight: 600;
  font-size: 0.9rem;
  padding: 0.5rem;
}
.blogCard img {
  width: 100%;
  padding: 0.5rem;
}

/* ACTIVITY LOG */

.activityLogContainer {
  margin-top: 2rem;
  grid-row: span 1;
}

.activityLogContainer h4 {
  color: #303447;
  font-size: 0.9rem;
  font-weight: 600;
}
.singleActivity {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}
.singleActivity > div > img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}
.singleActivity p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.singleActivity p:first-child {
  font-size: 0.9rem;
}
.singleActivity p:first-child span {
  color: #9620ff;
  font-weight: 600;
}
.singleActivity p:last-child {
  font-weight: 600;
  font-size: 0.85rem;
}

.customLoaderContainer {
  margin: 20px auto;
  width: 300px;
  height: 300px;
  animation: spin 3s linear infinite;
}
.customLoaderContainer > img {
  top: 0;
  left: 0;
  transform: rotate(90deg);
  height: 70px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: scale(1.05) rotate(72deg);
  }
  40% {
    transform: scale(0.95) rotate(144deg);
  }
  60% {
    transform: scale(1.05) rotate(216deg);
  }
  80% {
    transform: scale(0.95) rotate(288deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1200px) {
  .dashBottomContent {
    flex-direction: column;
  }
  /* .channelsTableContainer {
    grid-column: span 3;
  }
  .insightsContainer {
    grid-column: span 3;
  }
  .usersDevicePieContainer {
    grid-row: span 2;
  }
  .contactInfoLeft {
    flex-direction: column;
  }
  .contactInfoRight > div > span {
    display: none;
  } */
}
/* @media (max-width: 1000px) {
  .insightsContainer {
    grid-column: span 3;
  }
  .usersDevicePieContainer {
    grid-column: span 3;
  }
  .earningsMapContainer {
    grid-column: span 3;
  }
  .newsBlogContainer {
    grid-column: span 3;
  }
  .contactInfoSpin {
    grid-column: span 3;
  }
  .activityLogContainer {
    grid-column: span 3;
  }
} */
