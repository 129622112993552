.creativeVariations {
  flex: 1;
  position: relative;
  margin-right: 12px;
  overflow-x: auto;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 0 10px 0 #0000000d, 0 0 1px 0 #0000000d;
  margin: 10px;
  padding: 22px 18px;
}

.headerTitle {
  /* height: 80px; */
  color: #6e6e6e;
  display: flex;
  align-items: center;
}

.headerTitle > p {
  margin: 0;
}

.headerTitle span {
  color: #a6a6a6;
  font-size: 0.8rem;
}

.variationActions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* margin: 0.5rem 1rem 0 1rem; */
}

.variationActions :global(.ant-btn-dashed) {
  color: rgb(176, 176, 176);
}

.variationActions .primaryGhostBtn {
  height: 28px;
  border-radius: 4px;
  color: #4090f7;
  border-color: #4090f7;
  background-color: #fff;
  padding-right: 1rem;
  padding-left: 1rem;
}

.variationActions :global(.ant-radio-group) {
  display: flex;
}

.variationActions :global(.ant-radio-group .ant-radio-button-wrapper) {
  height: 28px;
  display: flex;
  align-items: center;
}

.variationActions
  :global(.ant-radio-group .ant-radio-button-wrapper:first-child) {
  border-radius: 5px 0 0 5px;
  display: flex;
}

.variationActions
  :global(.ant-radio-group .ant-radio-button-wrapper:last-child) {
  border-radius: 0 5px 5px 0;
}

.variationsGrid {
  padding: 1rem 0 0 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.gridSizeBox {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.maximumTrigger {
  color: rgb(216, 57, 57);
}

/* CREATIVE SAMPLE */
.sampleCreative {
  color: #373737;
  position: relative;
  padding: 0.9rem 1.6rem 0.9rem 0.9rem;
  background-color: #f8f8f86e;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid #e7e7e7;
  overflow: hidden;
}

.limitExceededSample {
  border: 2px solid rgb(206, 130, 130);
}

.selectedCreative {
  border: 2px solid #4090f7;
  color: #4090f7;
}

.sampleTitle,
.sampleDesc {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sampleCreativeContent {
  display: flex;
  flex-direction: column;
}

.imageSamplePreview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.imageSamplePreview > div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  /* padding-top: 10px; */
}

.imageSamplePreview > div p {
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
  font-weight: 500;
}

.imageSamplePreview > div span {
  font-size: 0.8rem;
}

.imageSamplePreview > div > img {
  height: 50px;
  width: 50px;
  min-width: 50px;
  object-fit: cover;
  /* border-radius: 7px; */
}

.imageSamplePreview > div > p {
  margin: 0;
  padding-left: 0.3rem;
}

.imageSamplePreview > img {
  /* border-radius: 7px; */
  padding-top: 0.7rem;
  width: 100%;
  height: 105px;
  object-fit: cover;
}

.sampleCreativeContent > p {
  font-weight: 500;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
}

.sampleCreativeContent > span {
  font-size: 0.8rem;
}

img.iconVariation {
  width: 50%;
  height: auto;
}

.sampleCreativeActions {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0.9rem;
  right: 0.4rem;
}

.sampleCreativeActions > div {
  display: flex;
  flex-direction: column;
}

.actionIcon {
  font-size: 0.8rem;
  margin-bottom: 6px;
  cursor: pointer;
  transition: color 0.2s;
}

.actionIcon:hover {
  color: rgb(74, 74, 189);
}

@media only screen and (max-width: 1380px) {
  .headerTitle {
    height: auto;
  }

  /* .headerTitle>p {
    padding: 1rem;
  } */
}

@media only screen and (max-width: 1240px) {
  .variationActions {
    flex-direction: column;
  }

  .variationActions div {
    margin-bottom: 0.2rem;
  }
}

@media only screen and (max-width: 1150px) {
  .creativeVariations {
    margin-left: 12px;
    margin-bottom: 12px;
  }

  .variationActions {
    flex-direction: row;
  }
}

@media only screen and (max-width: 620px) {
  .variationActions {
    flex-direction: column;
  }
}
