.apiTabWrapper {
  line-height: 2rem;
}

.apiTabCheckboxTitle {
  padding-left: 1rem;
  font-size: 1rem;
  font-weight: 600;
}

.switchBlock {
  display: flex;
  align-items: center;
}

.integrationContent :global(.ant-tabs-nav .ant-tabs-tab-active) {
  color: #7d7d7d;
  font-size: 1.05rem;
}

.integrationContent :global(.ant-tabs-ink-bar) {
  background-color: #7d7d7d;
}

.apiTabWrapper h2 {
  padding-top: 0.5rem;
  font-weight: bold;
  color: #4a4a4a;
}

.responseCodeExample {
  background-color: #f0f2f5;
  color: #4a4a4a;
  font-size: 0.8rem;
  width: 50%;
  min-width: 300px;
  max-width: 520px;
}

.codeDisplayColor {
  color: #1890ff;
  background-color: #cbe1f7;
  padding: 2px 3px;
  border-radius: 2px;
}

.apiTabWrapper h4 {
  text-decoration: underline;
  color: #4a4a4a;
}

.columnDescription {
  color: orangered;
}