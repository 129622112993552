/* GENERAL STYLING */

.paymentDetailsContainer {
  min-height: 70vh;
  position: relative;
  padding-bottom: 60px;
}
.paymentDetailsContainer :global(.ant-spin-container) {
  position: static;
}
.transactionDetails,
.customerInformation {
  color: #4d4f5c;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1100px;
  gap: 1.3rem;
  margin-bottom: 2.5rem;
}
.transactionDetails > h4,
.customerInformation > h4 {
  grid-column: 1 / 4;
  margin-bottom: 0.3rem;
  color: #4d4f5c;
  font-size: 1rem;
  font-weight: 700;
}

.paymentTitle {
  font-size: 1.2rem;
  color: #4d4f5c;
  font-weight: 600;
  margin-bottom: 0.7rem;
  text-transform: capitalize;
}
.tyMessage {
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  color: #4d4f5c;
  font-weight: 400;
}

.detailsPair > p {
  font-size: 0.9rem;
  margin: 0px;
}
.detailsPair > p:first-child {
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.tagStatus {
  display: inline;
  font-size: 0.85rem;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 3px;
  padding: 0.1rem 0.8rem;
}
.tagStatusPaid {
  background-color: #02875b1a;
  border: 1px solid #02875b;
  color: #02875b;
}
.tagStatusFailed {
  background-color: #d85c5c1a;
  border: 1px solid #d85c5c;
  color: #d85c5c;
}
.tagStatusCanceled {
  background-color: #faad141a;
  border: 1px solid #faad14;
  color: #faad14;
}

/* failed payment*/
.failPaymentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.failPaymentContainer > h4 {
  font-family:  Montserrat;
  font-size: 2rem;
  font-weight: 700;
  color: #44435b;
  margin-bottom: 1.875rem;
  /* margin-bottom: 30px; */
}
.failPaymentContainer > p {
  width: 70%;
  text-align: center;
  font: normal normal normal 1.05rem/1.125rem Montserrat;
  margin-bottom: 1.875rem;
  /* margin-bottom: 30px; */
}
.failPaymentContainer > img {
  width: auto;
  max-width: 190px;
  margin-top: 2.5rem;
  margin-bottom: 3.125rem;
}

.goBack {
  margin-top: 1.25rem;
  border-color: #ffaa2d;
  background: #ffaa2d 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 45px;
  padding: 0 70px 0px 70px;
  font: normal normal 600 1rem/1.625rem "Montserrat", sans-serif;
}

.enableCampaigns {
  border-top: 1px solid #f0f2f5;
  color: #4d4f5c;
  padding: 1.5rem 0;
}
.enableCampaigns > h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #4d4f5c;
  margin-bottom: 0.7rem;
}
.enableCampaigns > p {
  color: #4d4f5c;
  margin-bottom: 2rem;
}

@media (max-width: 1000px) {
  .transactionDetails,
  .customerInformation {
    grid-template-columns: repeat(2, 1fr);
  }
  .transactionDetails > h4,
  .customerInformation > h4 {
    grid-column: 1 / 3;
    font-size: 0.9rem;
  }
  .paymentTitle {
    font-size: 1rem;
  }
  .tyMessage {
    font-size: 0.9rem;
  }
  .detailsPair > p {
    font-size: 0.9rem;
  }
  .tagStatus {
    font-size: 0.9rem;
  }
  .enableCampaigns > h3 {
    font-size: 0.9rem;
  }
}

@media (max-width: 700px) {
  .transactionDetails,
  .customerInformation {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
  .transactionDetails > h4,
  .customerInformation > h4 {
    grid-column: 1 / 2;
    font-size: 0.9rem;
  }
  .paymentTitle {
    font-size: 0.95rem;
  }
  .tyMessage {
    font-size: 0.8rem;
  }
  .detailsPair > p {
    font-size: 0.8rem;
  }
  .tagStatus {
    font-size: 0.8rem;
  }
}
