.creativePreview {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 22px;
  margin-bottom: 10px;
  align-items: flex-end;
}

.creativePreviewHide {
  flex: 0 1;
  margin-left: 0;
}

.contentPreview {
  display: flex;
  margin-bottom: 0.8rem;
}

.contentPreview > img {
  height: 60px;
  width: 60px;
  object-fit: contain;
}

.pushLayout {
  display: flex;
  flex-direction: column-reverse;
}

.previewContainer > div {
  max-width: 350px;
  width: 100%;
  margin: 0;
  /* padding: 0.8rem; */
  /* border: 1px solid rgb(238, 238, 238); */
  /* border-radius: 5px; */
}

.creativePreviewMain {
  width: 100%;
  min-height: 400px;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 0 10px 0 #0000000d, 0 0 1px 0 #0000000d;
  margin: 10px;
  padding: 22px 18px;
}

.creativePreviewHide .creativePreviewMain {
  width: fit-content;
}

.creativePreviewTitleHide {
  display: none;
}

.headerTitle {
  color: #6e6e6e;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle > p {
  margin: 0;
}

.headerTitle span {
  color: #a6a6a6;
}

.previewContainer {
  display: flex;
  justify-content: center;
  padding: 1rem 2rem;
}

.creativePreviewHide .previewContainer {
  display: none;
}

.imagePreview {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.contentPreview > div > p:nth-child(1) {
  color: rgb(67, 67, 67);
  margin: 0.2rem 0 0 0.5rem;
  font-weight: 500;
  font-size: 1rem;
}

.contentPreview > div > p:nth-child(2) {
  color: rgb(177, 177, 177);
  margin: 0rem 0.5rem;
  font-size: 0.8rem;
}

.noSelection {
  color: #a9a9a9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  text-align: center;
}

.creativePreviewHide .noSelection {
  display: none;
  width: 0;
}

.creativesSaveBtn {
  color: #fff;
  background-color: #4090f7;
  border-color: #4090f7;
  border-radius: 4px;
  color: #fff;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

@media only screen and (max-width: 1150px) {
  .creativePreview {
    margin-left: 22px;
    margin-right: 0;
  }

  .creativePreviewMain {
    margin-left: 0;
    margin-bottom: 12px;
  }

  .noSelection {
    margin-top: 12px;
  }

  .creativePreviewHide .creativePreviewMain {
    height: fit-content;
    min-height: unset;
    width: 100%;
  }

  .creativePreviewHide :global(.ant-divider) {
    display: none;
  }

  .creativePreviewTitleHide {
    display: inline-block;
  }
}
