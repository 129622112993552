.interstitialNotificationWrapper :global(.ant-modal-content) {
    border-radius: 5px;
}

.interstitialNotificationWrapper :global(.ant-modal-close-x) {
    font-size: 14px;
}

.interstitialNotificationWrapper :global(.ant-modal-header) {
    border-bottom: none;
    display: flex;
    justify-content: center;
    border-radius: 5px 5px 0 0;
    padding: 30px 0;
}

.interstitialNotificationWrapper :global(.ant-modal-body) {
    padding: 0 20px;
    position: relative;
}

.notificationContent {
    height: 420px;
    padding: 0 20px;
    overflow-y: scroll;
}

.bottomShadow {
    position: absolute;
    bottom: 0;
    width: calc(100% - 80px);
    height: 20px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 40%, rgba(4, 59, 123, 0) 100%);
}

.interstitialNotificationWrapper :global(.ant-modal-header .ant-modal-title) img {
    width: 120px;
}

.interstitialNotificationWrapper :global(.ant-modal-footer) {
    border-top: none;
    border-radius: 0 0 5px 5px;
    padding: 20px;
}

.interstitialNotificationWrapper :global(.ant-modal-footer) .understandBtn {
    min-width: 200px;
}

.descriptionStyle,
.titleStyle {
    color: #4D4F5C;
}

.titleStyle p {
    font-size: 16px;
    font-weight: 700;
}

.descriptionStyle strong {
    font-size: 16px;
}