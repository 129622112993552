.headTabInfo {
  margin-top: 0.3rem;
  color: #4d4f5c;
}

.generalWrapper {
  width: 100%;
  /* min-width: 300px; */
  color: #4d4f5c;
  box-sizing: border-box;
}

.hiddenTxt {
  opacity: 0;
  user-select: none;
}

.flexCustomWidth {
  flex: 0 0 300px;
}

.ruleInput {
  width: 230px;
}

.errorRule {
  color: orangered;
  font-size: 0.85rem;
  font-style: italic;
}

.generalWrapper .ruleWrapper {
  padding-top: 1rem;
}

.generalWrapper .ruleWrapper:not(:first-child) {
  margin-top: 1rem;
  border-top: 1.5px solid #f3f3f3;
}

.generalWrapper .ruleWrapper :global(.ant-row .ant-col) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.generalWrapper .ruleWrapper :global(.ant-row .ant-col) > span {
  font-size: 12px;
}

.generalWrapper .ruleWrapper :global(.ant-row .ant-col).start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1 1 auto;
}

.generalWrapper .ruleWrapper :global(.ant-row .ant-col).end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.generalWrapper .ruleWrapper :global(.ant-row .ant-col).end :global(.ant-btn):first-child {
  margin-right: 0.5rem;
}

.generalWrapper .ruleWrapper :global(.ant-row .ant-input) {
  height: 32px;
}

.generalWrapper .ruleWrapper :global(.ant-row .ant-input-number) {
  width: 100px;
}

.generalWrapper .ruleWrapper :global(.ant-row):not(:last-child) {
  margin-bottom: 1rem;
}

.generalWrapper .ruleWrapper :global(.ant-row) > span {
  color: #4d4f5c;
  display: inline-block;
  padding-bottom: 0;
  font-size: 12px;
  line-height: 30px;
}

.noRules {
  color: #848484;
  font-style: italic;
  font-size: 0.8rem;
  margin-top: 1rem;
}

.italicText {
  color: #4d4f5c;
  font-style: italic;
  font-size: 0.8rem;
}

.bidUpdateContainer {
  display: flex;
  justify-content: center;
}

.bidUpdateContainer :global(.ant-btn) {
  padding: 0;
  width: 33px;
  border: 1px solid #d9d9d9;
  color: #4d4f5c;
  font-size: 13px;
  box-shadow: none;
}

.bidUpdateContainer .buttonLeft,
.bidUpdateContainer .buttonLeft :global(.ant-btn) {
  border-radius: 5px 0 0 5px;
}

.bidUpdateContainer .buttonRight,
.bidUpdateContainer .buttonRight :global(.ant-btn) {
  border-radius: 0 5px 5px 0;
}

.bidBox {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.bidBox * {
  box-shadow: none;
}

.bidBox :global(.ant-input-number) {
  border: none;
  border-radius: 0;
}

.errorBid.bidUpdateContainer :global(.ant-btn),
.errorBid.bidUpdateContainer .bidBox {
  border-color: rgb(255, 59, 59);
}

.bidBox :global(.ant-input-number:focus) {
  border: none;
  box-shadow: none;
}

.bidBox :global(.ant-input-number-input-wrap) input {
  padding: 0;
  text-align: center;
  /* color: #43425d; */
}

.bidBox :global(.ant-input-number-handler-wrap) {
  position: relative;
  display: none;
}

.statusRadioButton {
  flex-direction: row !important;
  align-items: center;
}

.radioButtonLabels {
  width: 75px;
  text-align: center;
}

.autoManualPricing {
  flex-direction: row !important;
  align-items: center;
}

.datepickerStart,
.datepickerEnd {
  width: 50%;
  display: inline-block;
}

.datepickerStart :global(.ant-col.ant-form-item-control) {
  margin-right: 10px;
}

.datepickerStart :global(.ant-picker),
.datepickerEnd :global(.ant-picker) {
  width: 100%;
}

.statusInfo {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.statusInfo > p {
  margin: 0;
  color: #000000d9;
  padding-right: 0.5rem;
}

.campaignBudget {
  display: inline-block;
  width: 60%;
  margin-right: 7%;
}

.campaignBudget input {
  min-height: 32px;
}

.campaignBudgetPeriod {
  display: inline-block;
  width: 33%;
}

/* TRACKING PREVIEW */
.generalInfoHeader,
.generalInfoRows {
  display: block;
  margin-bottom: 0.7rem;
  width: 100%;
}

.trackingInput {
  width: 80%;
  margin-bottom: 10px;
}

.trackingParagraph {
  margin-top: 0.5rem;
  color: darkgrey;
}

.trackingParamSpan {
  color: orangered;
}

.proceedButton {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin-top: 20px;
  border-radius: 4px;
  background-color: #4090f7;
}

.campaignLinkContainer {
  display: flex;
}

.conversionValueBox {
  display: flex;
  margin-left: 20px;
}

.campaignLinkSelect {
  flex: 1;
}

.manualAmountBox {
  width: 110px;
}

.customOffset {
  width: calc(100% - 220px);
}

.flexCustomWidth {
  flex: 0 0 220px;
}

.ruleInput {
  width: 160px;
}

.generalWrapper .ruleWrapper :global(.ant-row .ant-input-number) {
  width: 50px;
}

.generalWrapper .ruleWrapper :global(.ant-radio-button-wrapper) {
  padding: 0 8px;
}

.ruleFooterBtns {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
}

@media (max-width: 1100px) {
  .generalWrapper {
    /* width: 95%; */
    margin: 0 auto;
  }
}

@media (max-width: 1317px) {
  .customOffset {
    width: 100%;
  }
}

/* @media (max-width: 1080px) {
  .generalWrapper .ruleWrapper :global(.ant-row .ant-col).start {
    justify-content: space-between;
    flex: 0 0 100%;
  }

  .generalWrapper .ruleWrapper :global(.ant-row .ant-col).start :global(.ant-col) {
    margin-bottom: 0.5rem;
  }
} */
