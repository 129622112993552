:global(.ant-notification) {
  z-index: 1400;
}

.activationSection :global(.ant-spin-nested-loading) {
  width: 100%;
}

.activationSection :global(.ant-checkbox-wrapper) {
  color: #9d9d9d;
}

.activationSection .activation {
  display: flex;
  height: 100vh;
}

.formLabels {
  font-size: 0.875rem;
  margin-bottom: .5rem;
}

.activationSection :global(.ant-input-affix-wrapper) {
  font-weight: 300;
  width: 100%;
  padding: 1rem;
  border: 1px solid #ECECEC;
  background-color: #ffffff;
  border-radius: 8px;
}

.activationSection :global(.ant-form-item-explain.ant-form-item-explain-error) {
  font-size: 0.75rem;
}

.activationSection :global(.ant-input-password-icon) {
  width: 30px;
}

.activationSection :global(.ant-form-item):last-child {
  margin-top: 1.5rem;
  margin-bottom: .8rem;
}

.activationSection .logoLight {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 40px;
  left: 40px;
}

.activationSection .logoLight img {
  width: 120px;
}

.activationSection .logoLight p {
  color: white;
  font-size: 18px;
  margin-top: 8px;
  -webkit-text-stroke: 0.2px black;
}

.activationSection .activation .activationLeft .leftBg {
  height: 100%
}

.activationSection .activation .activationRight {
  padding: 0px 60px 60px 60px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.activationSection .activation .activationRight .center {
  align-self: center;
  width: 60%;
}

.activationSection .activation .activationRight img {
  max-width: 180px
}

.activationSection .activation .activationRight .activationForm h1 {
  color: #012d6c;
  font-weight: bold;
  font-size: 30px;
  margin: 15px 0px 30px 0px;
}

.activationSection .activation .activationRight .subTxt {
  color: #0071bd;
  text-decoration: underline;
  margin-top: 2%;
  display: inline-block;
}

.activationSection .activation .activationRight .box {
  padding: 20px 0px;
}

.activationSection .activation .activationRight .main-txt {
  color: #012d6c;
  font-weight: bold;
}

.activationSection :global(.ant-btn-primary) {
  display: block;
  width: 100%;
  height: auto;
  padding: 16px 0px;
  background-color: #0071bd;
  border-radius: 8px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.125rem;
}

/* 2FA modal */

.modal2FA :global(.ant-modal-content) {
  width: 550px;
}

.modal2FA :global(.ant-modal-body),
.modalBackupCodes :global(.ant-modal-body) {
  padding: 18px 24px 0;
}

.modal2FA :global(.ant-modal-footer),
.modalBackupCodes :global(.ant-modal-footer) {
  border-top: none;
  padding: 18px 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.modalBackupCodes :global(.ant-modal-footer) div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.modal2FA :global(.ant-modal-footer) .skipButton {
  color: #4D4F5C;
  border: 1px solid #E3E3E3;
}

.modal2FA :global(.ant-modal-footer .ant-btn-primary) {
  width: 200px;
}

.modalBackupCodes :global(.ant-modal-footer .ant-btn-primary) {
  width: 150px;
}

.twoFactorContainer h2,
.backupCodesContainer h2 {
  font-weight: 500;
  font-size: 0.9rem;
  color: #4D4F5C;
  margin-bottom: 1rem;
}

.twoFactorContainer p,
.backupCodesContainer p {
  color: #4D4F5C;
  font-size: 0.8rem;
}

.backupCodesContainer p.note {
  color: #F84949;
  font-style: italic;
}

.backupCodesContainer .codesWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.backupCodesContainer .codesWrapper span {
  color: #2D71B6;
  font-size: .9rem;
  font-weight: bold;
}

.twoFactorContainer p.emailSentStyle {
  margin: 0 0 0 .5rem;
  font-style: italic;
  color: #4d4f5c7d;
}

.twoFactorContainer h3 {
  font-weight: 500;
  font-size: 0.83rem;
  color: #4D4F5C;
}

.twoFactorContainer .firstStep {
  margin-bottom: .7rem;
}

.twoFactorContainer .firstStep :global(.ant-tag) {
  font-size: .7rem;
}

.twoFactorContainer .firstStep :global(.ant-tag):first-child {
  margin-left: .5rem;
}

.twoFactorContainer .firstStep .recommendedTag {
  color: #1990FF;
  border-color: #1990FF;
  background-color: #1990FF1A;
}

.twoFactorContainer .firstStep .activeTag {
  color: #73B666;
  border-color: #73B666;
  background-color: #73B6661A;
}

.twoFactorContainer .firstStep :global(.ant-collapse) {
  margin-bottom: .5rem;
  background-color: #ffffff;
  color: #4D4F5C;
  box-shadow: 0px 1px 2px #0000000D;
  border: 1px solid #E3E3E3;
}

.twoFactorContainer .firstStep :global(.ant-collapse>.ant-collapse-item) {
  border-bottom: none;
}

.twoFactorContainer .firstStep :global(.ant-collapse>.ant-collapse-item>.ant-collapse-header) {
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 16px 22px;
}

.twoFactorContainer .firstStep :global(.ant-collapse-content-box) {
  padding: 16px 22px;
  display: flex;
  align-items: center;
}

.twoFactorContainer .firstStep :global(.ant-collapse-item .ant-collapse-expand-icon .ant-collapse-arrow) {
  transform: rotate(90deg);
  color: #D1D1D1;
  margin-right: 0;
}

.twoFactorContainer .firstStep :global(.ant-collapse-item-active .ant-collapse-expand-icon .ant-collapse-arrow) {
  transform: rotate(180deg);
}

.twoFactorContainer .firstStep :global(.ant-radio-wrapper span) {
  font-size: .8rem;
  font-weight: 500;
  color: #4D4F5C;
}

.twoFactorContainer .firstStep :global(.ant-collapse-header-text p) {
  margin: .5rem 0 0 0;
  color: #4D4F5C;
}

.qrContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.qrContainer .imageWrapper {
  width: min-content;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  padding: .8rem;
  margin-right: 1.2rem;
}

.qrContainer .imageWrapper img {
  border: 1px solid #E3E3E3;
  border-radius: 5px;
}

.qrContainer .imageWrapper p {
  color: #4D4F5C;
  font-size: .8rem;
  text-align: center;
  margin: .8rem 0 0 0;
}

.qrContainer .keyWrapper {
  max-width: 400px;
}

.qrContainer .keyWrapper p {
  margin: .8rem 0;
}

.qrContainer .keyWrapper span.copyLabel {
  color: #1990FF;
  text-decoration: underline;
  cursor: pointer;
}

.twoFactorContainer .secondStep .codeWrapper {
  display: flex;
  justify-content: space-between;
}

.twoFactorContainer .secondStep .codeWrapper :global(.ant-input) {
  width: 32px;
  margin-right: .3rem;
}

.twoFactorContainer .thirdStep :global(.ant-btn) {
  width: 200px;
}

.errorCode {
  animation: wobble 1s 1;
}

@keyframes wobble {
  25% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@media (max-width: 1100px) {
  .activationSection .activation .activationRight .center {
    width: 80%;
  }
}

@media (max-width: 1000px) {
  .activationSection .activation {
    flex-direction: column;
    position: relative;
    top: -700px;
  }

  .activationSection .logoLight {
    display: none;
  }

  .activationSection .activation .activationRight .activationForm>img {
    display: block;
    margin: auto;
    margin-bottom: 1rem;
  }

  .activationSection .activation .activationRight h1 {
    text-align: center;
  }

  .activationSection .activation .activationLeft .leftBg {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 880px) {
  .activationSection .activation {
    top: -610px;
  }
}

@media (max-width: 760px) {
  .activationSection .activation {
    top: -500px;
  }
}

@media (max-width: 600px) {
  .activationSection .activation {
    top: -400px;
  }

  .activationSection .activation .activationRight .subTxt {
    margin-top: 5%;
  }

  .activationSection .activation .activationRight img {
    max-width: 120px;
  }

  .activationSection .activation .activationRight .center {
    width: 100%;
  }

  .activationSection form {
    width: auto;
  }

  .activationSection :global(.ant-spin-nested-loading) {
    width: auto;
  }

  .activationSection :global(.ant-btn-primary) {
    font-size: inherit;
  }

  .modal2FA :global(.ant-modal-content) {
    width: auto;
  }
}

@media (max-width: 500px) {
  .activationSection .activation {
    top: -250px;
  }

  .qrContainer .imageWrapper {
    margin-right: 0;
  }

  .qrContainer {
    flex-direction: column;
  }

  .modal2FA :global(.ant-modal-footer .ant-btn-primary) {
    width: auto;
  }
}

@media (max-width: 380px) {
  .modal2FA :global(.ant-modal-footer) {
    flex-direction: column;
  }

  .modal2FA :global(.ant-modal-footer .ant-btn-primary) {
    margin-left: 0 !important;
    margin-top: 0.5rem;
  }
}


@media (min-height: 1140px) and (max-width: 1366px) and (min-width: 1000px) {
  .activationSection .activation .activationLeft {
    display: none;
  }
}


@media (max-height: 700px),
(max-width: 600px) {
  .activationSection .activation .activationRight {
    padding: 40px;
    display: block;
  }

  .activationSection .activation .activationRight .activationForm h1 {
    font-size: 24px;
    line-height: 1;
  }

  .activationSection .activation .activationRight .center {
    padding-bottom: 40px;
    margin: auto;
  }
}