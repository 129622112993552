.statisticsLineHeader {
  margin-top: 1.5rem;
  padding: 1rem;
  font-size: 1.2rem;
  color: #4d4f5c;
  display: flex;
  justify-content: space-between;
}
.lineChartContainer {
  box-shadow: 0px 2px 6px #0000000a;
  margin-bottom: 1.5rem;
}
