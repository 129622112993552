.creativeBuilder {
  flex: 1;
  overflow: scroll;
  margin-right: 12px;
  margin-left: 12px;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 0 10px 0 #0000000d, 0 0 1px 0 #0000000d;
  margin: 10px;
  padding: 22px 18px;
}

.headerTitle {
  color: #6e6e6e;
  display: flex;
  align-items: center;
}

.headerTitle > p {
  margin: 0;
}

.headerTitle span {
  color: #a6a6a6;
}

.builderLabel {
  margin: 0.7rem 0rem 0.2rem 0rem;
  font-size: 0.8rem;
  color: #6e6e6e;
}

.builderLabel > span {
  color: rgb(191, 191, 191);
}

.builderLabel > span > span {
  color: rgb(255, 196, 0);
}

.buttonUpload {
  color: #4a90e2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  margin: 0 auto;
  border: 1px solid #4a90e2;
  border-radius: 5px;
  padding: 0.5rem 1rem;
}

.buttonUpload > span {
  padding-left: 0.5rem;
}

.pastUrlBox {
  margin-top: 1rem;
}

.postbackBtn {
  padding: 0 1.5rem;
  float: right;
}

.labelUrl {
  color: rgb(99, 99, 99);
  margin-top: 1rem;
  margin-bottom: 0.3rem;
}

.dynamicDelBtn {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s;
}

.dangerText {
  border: 1px solid #ff4d4f !important;
  /* box-shadow: 0 0 0 2px rgb(255 77 79 / 20%); */
}

.counterBox {
  text-align: right;
  white-space: nowrap;
  min-width: 45px;
  max-width: 45px;
  margin-top: 0.4rem;
  font-size: 0.7rem;
  color: rgb(99, 99, 99);
}

.dangerTextCounter {
  min-width: 45px;
  max-width: 45px;
  color: #ff4d4f !important;
  font-weight: bold;
}

.dynamicDelBtn:hover {
  color: #777;
}

.dynamicDelBtn[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

span.requiredMark {
  color: rgb(223, 109, 109);
  font-size: 0.9rem;
}

/* IMAGE PREVIEW */

.previeImageBox {
  padding: 0.5rem 0.3rem;
  /* margin: 0 0.8rem; */
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}

.singleImagePreview {
  width: 55px;
  height: 55px;
  margin: 4px;
  border-radius: 5px;
  position: relative;
}

.singleImagePreview > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.singleImageDelete {
  position: absolute;
  color: #ffffff;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
}

.singleImageDelete:hover {
  background-color: rgba(211, 50, 50, 0.871);
}

.addNewImageBtn {
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  color: rgb(183, 183, 183);
  border: 1px dashed rgb(183, 183, 183);
  border-radius: 5px;
  cursor: pointer;
}

.deleteIcon {
  font-size: 1rem;
  background-color: rgba(211, 50, 50, 0.871);
  padding: 0.2rem;
  border-radius: 50%;
  transform: translateX(22px) translateY(-22px);
  transition: all 0.2s;
}

.singleImageDelete:hover .deleteIcon {
  transform: translateX(0px) translateY(0px);
}

.editSaveBtn {
  margin-top: 10px;
  float: right;
}

.addNewImageBtn:hover {
  opacity: 0.7;
}

.macrosContent :global(.ant-divider-horizontal) {
  margin: 0;
}

.macrosContent span {
  cursor: pointer;
}

.macrosContent span:hover {
  font-weight: bold;
}

.headlinesBox {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.headlinesBox :global(.ant-input-affix-wrapper-focused),
.headlinesBox :global(.ant-input-affix-wrapper:focus),
.headlinesBox :global(.ant-input-affix-wrapper:hover),
.headlinesBox :global(.ant-input:focus),
.headlinesBox :global(.ant-input:hover) {
  border-color: #d9d9d9;
  box-shadow: none;
}

.headlinesBox :global(.ant-input-affix-wrapper) {
  /* border-top: none; */
  border-radius: 0px;
  margin-top: -2px;
}

.headlinesBox :global(.ant-input) {
  border-radius: 2px 2px 0 0;
}

.headlinesBox :global(.ant-input-group-addon) {
  cursor: pointer;
}

.buttonWrapper {
  margin: 0rem 0rem 0.8rem 0rem;
}

.buttonWrapper :global(.ant-btn) {
  color: #61636e;
  width: calc(100% - 45px);
  font-size: 0.75rem;
  height: 28px;
  margin-top: -2px;
  border-radius: 0 0 2px 2px;
}

.macroItem {
  cursor: pointer;
  display: inline-block;
  color: rgb(99, 99, 99);
  border: 1px solid rgb(229, 229, 229);
  padding: 0.25rem 0.6rem;
  margin-right: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 40px;
  transition: all 0.3s;
}

.macroItemSelected {
  cursor: pointer;
  display: inline-block;
  color: rgb(255, 196, 0);
  border: 1px solid rgb(255, 196, 0);
  padding: 0.25rem 0.6rem;
  margin-right: 0.6rem;
  margin-bottom: 0.5rem;
  border-radius: 40px;
  transition: all 0.3s;
}

.macroItem:hover {
  opacity: 0.7;
}

.macroItemSelected:hover {
  opacity: 0.7;
}

.macroInfoBtn {
  padding: 0;
  margin: 0;
}

.macroInfoBtn > span {
  text-decoration: underline;
}

.aiGeneratorModal {
  top: 40px;
}

.aiGeneratorModal :global(.ant-radio-button-wrapper) {
  margin-right: 0.5rem;
  border-radius: 5px;
  border-width: 1.02px 1px 1px 1px;
}

.aiGeneratorModal :global(.ant-radio-button-wrapper)::before {
  display: none;
}

.aiGeneratorModal :global(.ant-spin-text) {
  font-size: 1.1rem;
}

.aiGeneratorModal :global(.ant-spin-blur) {
  opacity: 0.16;
}

.aiGeneratorModalDescription {
  padding-left: 1rem;
  color: #989898;
  border-left: 2px solid #eeeeee;
}

.offerKeywordDescription {
  padding-top: 0.4rem;
  margin-bottom: 1rem;
  color: #4d4f5c;
}

.codeBlock {
  padding: 2px;
  background-color: #cccccc1a;
  border: 1px solid #cccccc;
  border-radius: 3px;
}

.aiGeneratorFormSubmitButton {
  margin-left: 0.5rem;
  border-radius: 3px;
}

.creativesAutoAiGeneratorButton {
  width: 100%;
  height: auto;
  white-space: normal;
  border-radius: 5px;
}

.formInputInline {
  justify-content: space-between;
}

.formInputInline :global(.ant-form-item) {
  flex-direction: row-reverse;
}

.formInputInline :global(.ant-form-item-label) {
  display: inline-flex;
  align-items: center;
  padding: 10px 0px 10px 10px;
}

.formInputInline :global(.ant-form-item-control) {
  display: inline-flex;
  justify-content: center;
  max-width: fit-content;
}

.formInputInline :global(.ant-form-item-control-input) {
  width: fit-content;
}

.formInputInline :global(.ant-form-item-control-input-content) {
  display: inline-block;
}

.formLanguageRadioGroup :global(.ant-radio-button-wrapper) {
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
  border-radius: 15px;
}

.formLanguageRadioGroup :global(.ant-radio-button-wrapper):hover {
  color: #ffaa2d;
}

.formLanguageRadioGroup
  :global(
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)
  ):first-child {
  border-color: #ffaa2d;
}

.formLanguageRadioGroup
  :global(
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)
  ) {
  color: #ffaa2d;
  border-color: #ffaa2d;
}

.formLanguageRadioGroup :global(.ant-radio-button-wrapper:focus-within) {
  box-shadow: 0 0 0 3px rgba(255, 170, 45, 0.12);
}
.callToAction {
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin-top: 10px;
}

@media only screen and (max-width: 1150px) {
  .creativeBuilder {
    margin-bottom: 12px;
  }
}
