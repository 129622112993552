/* TIME TABLE */

.timesTableWrapper {
  max-width: 100%;
  overflow-y: scroll;
  color: rgb(80, 80, 80);
  font-size: 12px;
}

.timeTable {
  min-width: 680px;
  border-collapse: collapse;
  border-radius: 20px;
}

.timeTable,
.timeTable th,
.timeTable td {
  border: 1px solid #00000015;
  position: relative;
}

.timeRow td {
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.rowTitle {
  padding: 0 0.7rem;
  text-transform: capitalize;
}
.timeHour {
  cursor: pointer;
}
.timeHourSelected::before {
  position: absolute;
  content: "";
  display: inline-block;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background-color: #fbaf16;
  transform: translate(-50%, -50%);
}
.timeRowSelected td:nth-child(1)::before,
.timeRowSelected td:nth-child(2)::before,
.timeRowSelected td:nth-child(1)::before,
.timeRowSelected td:nth-child(2)::before,
.hoursCheckbox::before {
  display: none;
}
