.tracking-main-title {
  color: #43425d;
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.testConversionTracking {
  width: 70%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.formTitles {
  font: Bold 18px/21px Arial;
  letter-spacing: 0px;
  color: #555555;
}

.formSmallWritings {
  font: Regular 15px/24px Source Sans Pro;
  letter-spacing: 0px;
  color: #a6a6a6;
}
.formSmallWritings .enhance {
  color: #ff6b40;
}

.noConversions {
  letter-spacing: 0px;
  color: #d95c5c;
  opacity: 1;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.gutter-row {
  margin-bottom: 10px;
}
