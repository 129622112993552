.previewContainer {
  flex: 1;
  max-width: 240px;
  min-width: 150px;
  border-left: 1px solid rgb(233, 233, 233);
  background-color: #fff;
  padding: 15px 20px;
  font-size: 0.75rem;
  overflow: scroll;
}
.previewContainer > h2 {
  font-size: 0.8rem;
  color: #4d4f5c;
  font-weight: 600;
  padding-bottom: 10px;
}
.previewSection > .targetingInfoHeader {
  color: #555555;
  font-size: 0.75rem;
  font-weight: 600;
}
.previewSection {
  margin-bottom: 20px;
}
.targetingInfoValue {
  border: 1px solid #1990ff;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  color: #1990ff;
  display: inline-block;
  background-color: #1990ff1a;
  text-transform: capitalize;
}

@media screen and (max-width: 980px) {
  .previewContainer {
    display: none;
  }
}
