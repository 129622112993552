.radioButtonSelection {
  margin: 15px 0;
}

.radioButtonSelection :global(.ant-radio-wrapper) {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.radioButtonSelection :global(.ant-radio-wrapper) > span:last-child {
  padding-top: 4px;
}

.specificItemSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.resetBtnTargeting {
  color: rgb(98, 98, 98);
  border-radius: 5px;
}
