.btnOption {
  margin-right: 10px;
  border-radius: 4px;
}

.selectContainer {
  display: flex;
  flex-direction: column;
}

.selectContainer>span {
  padding-bottom: 8px;
  color: #4d4f5c;
  font-size: 0.8rem;
}

.paddingTop {
  padding-top: 20px;
}

.radioButtonSelection {
  margin: 15px 0;
}

.radioButtonSelection :global(.ant-radio-wrapper) {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.radioButtonSelection :global(.ant-radio-wrapper)>span:last-child {
  padding-top: 4px;
}

.targetingTagSelect {
  cursor: pointer;
  font-size: 0.8rem;
  display: inline-block;
  color: rgb(123, 123, 123);
  border-radius: 50px;
  padding: 0px 8px;
  margin: 0 12px 8px 0;
  border: 1px solid rgb(205, 205, 205);
}

.targetingTagSelected {
  border: 1px solid #faad14;
  color: #faad14;
}

.regionMessage {
  font-size: 0.8rem;
  color: rgb(123, 123, 123);
}