.targetingInfoHeader {
  width: 100%;
  margin-bottom: 10px;
}

.targetingInfoQuantity {
  width: 100%;
  margin-bottom: 5px;
}

.targetingInfoType {
  text-transform: capitalize;
  /* width: 100px; */
  margin-right: 0.5rem;
  display: inline-block;
  font-weight: 500;
  color: darkgrey;
}

.targetingInfoValue {
  border: 1px solid #1990ff;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  color: #1990ff;
  display: inline-block;
  background-color: #1990ff1a;
  text-transform: capitalize;
}

.targetingInfoValueList {
  text-transform: capitalize;
  background-color: #ffffff;
  color: #1890ff;
  font-weight: 500;
  padding: 2px 0px;
  border-radius: 5px;
  margin-right: 10px;
}

.targetingInfoSubidValue {
  border: 1px solid #1990ff;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  color: #1990ff;
  display: inline-flex;
  background-color: #1990ff1a;
}

.targetingInfoSubidList {
  border: 1px solid #1990ff;
  background-color: #1990ff1a;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  color: #1990ff;
  display: inline-flex;
  align-items: baseline;
}

.targetingInfoSubidList :global(.anticon) {
  margin-right: .2rem;
  height: 14px;
  top: 1px;
  position: relative;
}

.targetingInfoRow {
  width: 100%;
  margin-bottom: 20px;
}