.headTabInfo {
  display: flex;
  flex-direction: column;
}
.headTabInfo > div {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.headTabInfo > div > p {
  font-size: 0.8rem;
  /* margin: 0px; */
}
.headTabInfo > p {
  color: rgb(103, 103, 103);
}

.headTabInfo {
  display: flex;
  flex-direction: column;
}

.actionTimeButtons :global(.ant-btn) {
  margin-right: 10px;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 3px 10px;
  height: 28px;
}
.actionTimeButtons :global(.ant-btn-primary) {
  background-color: #4090f7;
  border-color: #4090f7;
}

.timesInfoLabel {
  padding-top: 1rem;
  color: #1990ff;
  font-size: 0.8rem;
  height: 53px;
}
