.trackerLogoContainer {
  width: 140px;
  height: 70px;
  cursor: pointer;
  padding: 20px;
  font-weight: 600;
  color: rgb(90, 90, 90);
  font-size: 0.9rem;
  border: 2px solid rgb(237, 237, 237);
  background-color: rgb(251, 251, 251);
  opacity: 0.7;
  border-radius: 3px;
}
.ant-row-start .ant-space-horizontal:last-of-type .trackerLogo {
  width: auto;
  text-align: center;
  background: none;
  padding: 0px;
  line-height: 1;
  font-size: smaller;
}
.trackerLogoContainerSelected {
  width: 140px;
  height: 70px;
  cursor: pointer;
  padding: 20px;
  font-weight: 600;
  color: rgb(90, 90, 90);
  font-size: 0.9rem;
  background-color: rgb(251, 251, 251);
  border: 2px solid #1890ff;
  border-radius: 3px;
}
.trackerLogo {
  width: 90px;
  height: 30px;
}
