.itemVersionContainer {
  border: 1px solid rgb(233, 233, 233);
  border-radius: 4px;
  padding: 10px 15px;
  margin: 20px 0;
  position: relative;
}
.itemVersionContainer > p {
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 4px;
}

.itemVersionContent {
  display: flex;
}
.itemVersionContent > div:first-child {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.itemVersionContent > div:last-child {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  flex: 3;
}
.itemVersionContent > div > span,
.noVersionsAvailable {
  color: #4d4f5c;
  display: inline-block;
  padding-bottom: 4px;
  font-size: 0.8rem;
}
.closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  color: #b0b0b0;
  padding: 0 5px;
  cursor: pointer;
}
.closeIcon:hover {
  color: #797979;
}
