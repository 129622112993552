.contentFormContainer {
  flex: 3;
  overflow: scroll;
  padding: 20px;
}
.contentFormContainer .lastContainerSection {
  margin-bottom: calc(87vh - 290px);
}
.contentFormContainer h5,
.contentFormContainer h6 {
  padding-top: 16px;
  margin-top: -16px;
}

.contentFormContainer h6 {
  padding-top: 25px;
  margin-bottom: 20px;
}
.formSectionTitle {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: rgb(94, 94, 94);
  font-weight: 400;
}
.formSectionTitle > span {
  flex: 1;
  background-color: rgb(215, 215, 215);
  margin: 15px;
  height: 1px;
}

.formSectionContainer {
  background-color: #fff;
  border-radius: 7px;
  padding: 10px 22px;
  margin: 20px 0;
  box-shadow: #0000000d 0px 0px 10px 0px, #0000000d 0px 0px 1px 0px;
}
.formSectionContainer > h6 {
  font-size: 0.85rem;
  font-weight: 600;
}

.sectionIcon {
  height: 15px;
  width: 15px;
  margin-bottom: 1px;
  margin-right: 8px;
}
