.generalWrapper {
  display: flex;
}
.generalWrapper > div {
  flex: 1;
}
.generalWrapper > div:first-child {
  margin-right: 10px;
}
.generalWrapper > div:last-child {
  margin-left: 10px;
}
.formLabel {
  color: #4d4f5c;
  display: inline-block;
  padding-bottom: 4px;
  font-size: 0.8rem;
}

.campaignVerticalSelect,
.campaignNameInput {
  width: 100%;
}

.formInputValidate {
  min-height: 56px;
}

.formInputVertical {
  display: flex;
  align-items: center;
}
.formInputVertical :global(.ant-row.ant-form-item) {
  display: inline-block;
  margin-bottom: 0;
}

.generalWrapper :global(.ant-input-show-count-suffix) {
  font-size: 12px;
}

.statusRadioButton {
  margin-left: 15px;
}

.statusRadioButton :global(.ant-radio-button-wrapper:first-child) {
  border-radius: 5px 0 0 5px;
}
.statusRadioButton :global(.ant-radio-button-wrapper:last-child) {
  border-radius: 0 5px 5px 0;
}

@media screen and (max-width: 1360px) {
  .generalWrapper {
    flex-direction: column;
  }
  .generalWrapper > div:first-child {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .generalWrapper > div:last-child {
    margin-left: 0px;
  }
  .formInputVertical {
    flex-direction: column;
    align-items: flex-start;
  }
  .statusRadioButton {
    margin-left: 0px;
  }
}
