.headInfoContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

/* HEAD INFO CARD */
.infoCard {
  box-shadow: 0px 2px 6px #00000012;
  padding: 0.9rem;
}
.infoCard * {
  margin: 0;
  color: #a3a6b4;
}
.infoCard > p {
  font-size: 1.2rem;
  padding-bottom: 0.7rem;
}
.cardValues p:first-child {
  font-size: 1.6rem;
  font-weight: bold;
}
.cardValues p:last-child {
  line-height: 1rem;
}

@media (max-width: 1300px) {
  .headInfoContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  .infoCard {
    padding: 0.3rem;
  }
}

@media (max-width: 1000px) {
}
