.creativesTable {
  width: 100%;
}

.creativesTable :global(.ant-table-cell) {
  font-size: 12px;
}

.creativesTable :global(.ant-table-thead > tr > th) {
  background-color: #ffffff;
  color: #747474;
}

.actionsButton {
  border: none;
  padding: 5px;
}

.statusSpan {
  color: white;
  display: block;
  padding: 0.2rem 0.8rem;
  border-radius: 7px;
  text-align: center;
}

.newCreativeButton {
  margin-top: 1rem;
  color: #40a9ff;
  background-color: white;
  box-shadow: none;
  border: none;
}

.campaignTypeModal {
  width: 80vw !important;
  height: 80vh !important;
  margin-right: 100px;
}

.campaignTypePushCpa {
  color: #32b66b;
}

.campaignTypePUSH {
  color: #d95c5c;
}

.campaignTypeNATIVE {
  color: #3067ce;
}

.campaignTypeSMARTLINK {
  color: #781edf;
}

.statusactive {
  display: block;
  text-align: center;
  text-transform: capitalize;
  min-width: 90px;
  max-width: 110px;
  font-weight: 500;
  border: 1px solid;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  background-color: #02875b1a;
  border-color: #02875b;
  color: #02875b;
}

.statuspaused {
  display: block;
  text-align: center;
  text-transform: capitalize;
  min-width: 90px;
  max-width: 110px;
  font-weight: 500;
  border: 1px solid;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  background-color: #d1d1d11a;
  border-color: #d1d1d1;
  color: #d1d1d1;
}

.statusinactive {
  display: block;
  text-align: center;
  text-transform: capitalize;
  min-width: 90px;
  max-width: 110px;
  font-weight: 500;
  border: 1px solid;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}

.campaignType {
  /* width: 120px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaignType {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  font-weight: bold;
  /* font-size: 1rem; */
}

.deletedRow {
  display: none;
}

.creativeName {
  width: 220px;
}

.creativeBuilderLeft {
  width: 50%;
  padding-right: 10px;
}

.creativePreviewRight {
  width: 50%;
}

.columnWidth {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableImgPreview {
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px;
}

.rangeDatePicker {
  position: relative;
  padding-bottom: 10px;
}

.rangeDatePicker>p {
  font-size: 12px;
  padding-top: 10px;
  color: rgb(100, 100, 100);
}

@media (max-width: 1100px) {
  .newCreativeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .creativeBuilderLeft {
    width: 90%;
    padding: 0;
  }

  .creativePreviewRight {
    width: 90%;
    margin-bottom: 1rem;
  }

  .creativeSaveContainer {
    width: 90%;
    margin: 0 auto;
  }
}