.buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.newCreativeButton {
  height: 36px;
  margin-top: 20px;
  border-radius: 4px;
  color: #fff;
  border-color: #4090f7;
  background-color: #4090f7;
  padding-right: 1.3rem;
  padding-left: 1.3rem;
  margin-bottom: 20px;
  margin-right: 20px;
}

.newCreativeButtonSecond {
  height: 36px;
  margin-top: 20px;
  border-radius: 4px;
  color: #4090f7;
  border-color: #4090f7;
  background-color: #fff;
  padding-right: 1.3rem;
  padding-left: 1.3rem;
  margin-bottom: 20px;
  margin-right: 20px;
}

.finishCreativeButton {
  border-radius: 4px;
  margin-top: 20px;
  color: seagreen;
  border-color: seagreen;
}

.creativesContentWrapper>p {
  font-size: 12px;
  padding-top: 10px;
  color: rgb(100, 100, 100);
}

.actionButtonsCreative {
  display: flex;
  justify-content: flex-end;
}