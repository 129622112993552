.nestedTableContainer :global(.ant-table-title) {
  padding: 0px;
}

.nestedTableContainer :global(table) {
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  overflow: hidden;
}

.nestedTableContainer :global(.ant-table-row:nth-child(odd)) {
  background-color: #fff;
}

.nestedTableContainer :global(.ant-table-footer) {
  background-color: #fff;
}

.nestedTableContainer :global(.ant-table-pagination.ant-pagination) {
  margin: 8px 0;
}

/* .nestedTableContainer :global(.ant-table-tbody > tr > td) {
  padding: 10px !important;
}
.nestedTableContainer :global(.ant-table-thead > tr > th) {
  padding: 10px !important;
} */

.nestedTableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.statsBy {
  margin: 1rem 0 2rem 0;
  display: flex;
  align-items: center;
}

.statsBy>span {
  margin-right: 1rem;
}

.multipleAction {
  margin-left: 1rem;
}

.multipleAction button {
  margin-right: .5rem;
}

.multipleAction .active,
.multipleAction .active:hover,
.multipleAction .active:focus,
.multipleAction .included,
.multipleAction .included:hover,
.multipleAction .included:focus {
  color: #73B566 !important;
  border-color: #73B566 !important;
}

.multipleAction .paused,
.multipleAction .paused:hover,
.multipleAction .paused:focus {
  color: #FAAD14 !important;
  border-color: #FAAD14 !important;
}

.multipleAction .excluded,
.multipleAction .excluded:hover,
.multipleAction .excluded:focus {
  color: #d82a2a !important;
  border-color: #d82a2a !important;
}

.actionBtn {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.actionBtn.included {
  color: #d82a2a;
}

.actionBtn.excluded {
  color: #73B566;
}

.actionBtn.active {
  color: #FAAD14;
}

.actionBtn.paused {
  color: #73B566
}

.pricingColumn {
  display: flex;
  justify-content: flex-end;
}

.pricingColumn .confirmButtonDisabled {
  position: relative;
  top: 4px;
  right: 0;
  margin-left: .8rem;
}

.pricingColumn .confirmButtonGreen {
  position: relative;
  top: 4px;
  right: 0;
  margin-left: .8rem;
  color: #73B566;
  border: 1px solid #73B566;
  background-color: #73B5661a;
}

.cpcTitle {
  margin-right: -28%;
}

.actionColumn {
  width: 200px;
}

@media (max-width: 1600px) {
  .cpcTitle {
    margin-right: -10%;
  }

  .actionColumn {
    width: auto;
  }
}

.pricingUndoBtn {
  font-size: 1.2rem;
  cursor: pointer;
  margin-left: 1rem;
}

.percentageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.priceUpdateContainer {
  display: flex;
  margin-left: 1rem;
}

.priceUpdateContainer :global(.ant-btn) {
  padding: 0;
  height: 100%;
  width: 33px;
  border: 1px solid #EEEEEE;
  color: #4D4F5C;
  font-size: 13px;
  box-shadow: none;
  border-radius: 0 5px 5px 0;
}

.priceUpdateContainer :global(.ant-btn:first-child) {
  border-radius: 5px 0 0 5px;
}

.priceEditPop {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
}

.priceEditBtns {
  display: flex;
  justify-content: space-between;
}

.editPriceBtn {
  margin-left: 0.6rem;
  margin-right: 1rem;
}

.pricingColumn :global(.ant-radio-group-small .ant-radio-button-wrapper) {
  padding: 0 4px;
}

/* .pricingColumn > span {
  width: 60px;
} */

.mainPriceBox {
  /* padding: 0.3rem; */
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
}

.mainPriceBox * {
  box-shadow: none;
}

.mainPriceBox :global(.ant-input-number) {
  border: none;
  border-radius: 0;
}

.mainPriceBox :global(.ant-input-number:focus) {
  border: none;
  box-shadow: none;
}

.mainPriceBox :global(.ant-input-number-input-wrap) input {
  padding: 0;
  text-align: center;
  color: #43425d;
}

.mainPriceBox :global(.ant-input-number-handler-wrap) {
  position: relative;
  display: none;
}

.percentageBox {
  font-size: 12px;
  line-height: 1.3;
  min-width: 45px;
  background-color: #1990FF1A;
  border-radius: 2px;
  border: 1px solid #1990F9;
  color: #1990F9;
  padding: 2px 4px;
}

/* STATUS AREA */

.statusColumn,
.status,
.campaignType {
  display: flex;
  align-items: center;
  justify-content: center;
}

.status {
  text-transform: capitalize;
  min-width: 90px;
  max-width: 90px;
  position: relative;
  margin: 3px 0;
  font-weight: 400;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  border: 1px solid transparent;
  background-color: #d1d1d11a;
  border: 1px solid #d1d1d1;
  color: #d1d1d1;
}

.statusactive {
  background-color: #02875b1a;
  border-color: #02875b;
  color: #02875b;
}

.statuspaused,
.statussuspended {
  background-color: #d1d1d11a;
  border-color: #d1d1d1;
  color: #d1d1d1;
}

.statusrejected {
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}

.statuspending {
  background-color: #faad141a;
  border-color: #faad14;
  color: #faad14;
}

.statusready {
  background-color: #02875b1a;
  border: 1px solid #02875b;
  color: #02875b;
}

.statusimplementing {
  background-color: #1990ff1a;
  border: 1px solid #1990ff;
  color: #1990ff;
}

.statusdisabled {
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}

.statusrequested {
  background-color: #781edf1a;
  border-color: #781edf;
  color: #781edfc9;
}

.creativeImageContainer {
  width: 90px;
  height: 50px;
  border-radius: 3px;
  background-position: center;
  background-size: contain;
}

.creativeImageIcon {
  width: 50px;
  height: 50px;
  border-radius: 3px;
  background-position: center;
  background-size: contain;
}

.landingUrl {
  margin: 0;
  width: 250px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}