.tableOfContentsContainer {
  flex: 1;
  max-width: 240px;
  min-width: 150px;
  border-right: 1px solid rgb(233, 233, 233);
  background-color: #fff;
  padding-top: 10px;
}

.tableOfContentsContainer ul {
  list-style: none;
  padding-left: 0;
}
.tableOfContentsContainer > ul > li > a {
  color: #7c7c7c;
  font-size: 0.85rem;
}
.tableOfContentsContainer > ul > li {
  padding: 2px 0;
}

.tableContentItem > a {
  display: block;
  color: #989898;
  padding: 3px 20px;
}
.tableOfContentsContainer > ul > li > ul {
  margin-left: 18px;
  font-size: 0.75rem;
}
.tableContentItemActive > a {
  color: #1990ff;
  border-right: 1px solid #1990ff;
}
.tableOfContentsContainer > ul > .tableContentItemActive > a {
  color: #1990ff;
  border-right: 1px solid #1990ff;
}

@media screen and (max-width: 980px) {
  .tableOfContentsContainer {
    display: none;
  }
}
