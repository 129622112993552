.resetPassFormContainer :global(.ant-spin-nested-loading){
  width: 100%;
}

.activationSection :global(.ant-checkbox-wrapper){
  color: #9d9d9d;
}


.resetPassFormContainer .reset {
  display: flex;
  height: 100vh;
}

.formLabels {
  font-size: 0.875rem;
  margin-bottom: .5rem;
}

.resetPassFormContainer :global(.ant-input-affix-wrapper){
  font-weight: 300;
  width: 100%;
  padding: 1rem;
  border: 1px solid #ECECEC;
  background-color: #ffffff;
  border-radius: 8px;
}

.resetPassFormContainer :global(.ant-form-item-explain.ant-form-item-explain-error){
  font-size: 0.75rem;
}

.resetPassFormContainer :global(.ant-input-password-icon){
  width: 30px;
}

.resetPassFormContainer :global(.ant-form-item):last-child {
  margin-top: 1.5rem;
  margin-bottom: .8rem;
}

.resetPassFormContainer .logoLight {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 40px;
  left: 40px;
}

.resetPassFormContainer .logoLight img {
  width: 120px;
}

.resetPassFormContainer .logoLight p {
  color: white;
  font-size: 18px;
  margin-top: 8px;
  -webkit-text-stroke: 0.2px black;
}

.resetPassFormContainer .reset .resetLeft .leftBg {
  height: 100%
}

.resetPassFormContainer .reset .resetRight {
  padding: 0px 60px 60px 60px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.resetPassFormContainer .reset .resetRight .containerResetPass {
  align-self: center;
  width: 60%;
}

.resetPassFormContainer .reset .resetRight img {
  max-width: 180px
}

.resetPassFormContainer .reset .resetRight .formHeader h1 {
  color: #012d6c;
  font-weight: bold;
  font-size: 30px;
  margin: 15px 0px 30px 0px;
}

.resetPassFormContainer .reset .resetRight .formHeader h2 {
  color: #012d6c;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 1rem;
}

.resetPassFormContainer .reset .resetRight .subTxt {
  color: #0071bd;
  text-decoration: underline;
  margin-top: 2%;
  display: inline-block;
}

.resetPassFormContainer .reset .resetRight .box {
  padding: 20px 0px;
}

.resetPassFormContainer .reset .resetRight .main-txt {
  color: #012d6c;
  font-weight: bold;
}

.resetPassFormContainer :global(.ant-btn-primary) {  
  display: block;
  width: 100%;
  height: auto;
  padding: 16px 0px;
  background-color: #0071bd;
  border-radius: 8px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.125rem;
}

@media (max-width: 1100px) {
  .resetPassFormContainer .reset .resetRight .containerResetPass {
    width: 80%;
  }
}

@media (max-width: 1000px) {
  .resetPassFormContainer .reset {
    flex-direction: column;
    position: relative;
    top: -700px;
  }

  .resetPassFormContainer .logoLight {
    display: none;
  }

  .resetPassFormContainer .reset .resetRight .formHeader > img {
    display: block;
    margin: auto;
    margin-bottom: 1rem;
  }

  .resetPassFormContainer .reset .resetRight h1 {
    text-align: center;
  }

  .resetPassFormContainer .reset .resetLeft .leftBg {
    height: 100%;
    width: 100%;
  }

  .resetPassFormContainer .reset .resetRight .formHeader h1, .resetPassFormContainer .reset .resetRight .formHeader h2 {
    text-align: center;
  }
}

@media (max-width: 880px) {
  .resetPassFormContainer .reset {
    top: -610px;
  }
}

@media (max-width: 760px) {
  .resetPassFormContainer .reset {
    top: -500px;
  }
}

@media (max-width: 600px) {
  .resetPassFormContainer .reset {
    top: -400px;
  }

  .resetPassFormContainer .reset .resetRight .subTxt {
    margin-top: 5%;
  }

  .resetPassFormContainer .reset .resetRight img {
    max-width: 120px;
  }

  .resetPassFormContainer .reset .resetRight .containerResetPass {
    width: 100%;
  }

  .resetPassFormContainer form {
    width: auto;
  }

  .resetPassFormContainer :global(.ant-spin-nested-loading){
    width: auto;
  }

  .resetPassFormContainer :global(.ant-btn-primary) {  
    font-size: inherit;
  }
}

@media (max-width: 500px) {
  .resetPassFormContainer .reset {
    top: -250px;
  }
}


@media (min-height: 1140px) and (max-width: 1366px) and (min-width: 1000px){
  .resetPassFormContainer .reset .resetLeft {
    display: none;
  }
}


@media (max-height: 700px),
(max-width: 600px) {
  .resetPassFormContainer .reset .resetRight {
    padding: 40px;
    display: block;
  }

  .resetPassFormContainer .reset .resetRight .formHeader h1 {
    font-size: 24px;
    line-height: 1;
  }

  .resetPassFormContainer .reset .resetRight .containerResetPass {
    padding-bottom: 40px;
    margin: auto;
  }
}
