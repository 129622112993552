.creativeGridContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.creativeBuilder {
  grid-column: 1 / 4;
  grid-row: 1 / 3;
  border: 1px solid rgb(204, 204, 204);
  margin-right: 12px;
  margin-left: 12px;
}
.creativePreview {
  grid-column: 4 / 6;
  border: 1px solid rgb(204, 204, 204);
  margin-right: 12px;
  margin-left: 12px;
}
.finalContainer {
  grid-column: 4 / 6;
  margin-right: 12px;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.finalContainer > p {
  color: #989898;
  padding: 1rem 0.3rem;
}

.adPreviewScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(148, 148, 148);
  padding: 0.7rem 1rem;
  /* width: 65%; */
  max-width: 680px;
  margin: 0 auto;
}

.headerTitle {
  background-color: #fbfbfd;
  border-bottom: 1px solid rgb(204, 204, 204);
  height: 80px;
  color: #4d4f5c;
  display: flex;
  align-items: center;
}
.headerTitle > p {
  margin: 0;
  padding-left: 1rem;
}
.headerTitle span {
  color: #a6a6a6;
}

.formVariationsHeader,
.inputVariant {
  display: flex;
  align-items: center;
}
.formVariationsHeader > p,
.inputVariant :global(.ant-form-item) {
  flex: 1;
}
.inputVariant :global(.ant-form-item) {
  margin-right: 1rem;
}
.inputVariant :global(.ant-form-item-explain) {
  margin-left: 10px;
}

.inputLandingUrl {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.formContainer {
  min-height: 540px;
  padding: 1rem;
}

.headtitle {
  color: #323232;
  font-size: 1rem;
  margin-bottom: 0.4rem;
  font-weight: bold;
}

.adPreviewContentBox {
  display: flex;
  flex-wrap: wrap;
}

.adPreviewContentBox > div {
  position: relative;
  flex-basis: 49%;
  margin-right: 1%;
  margin-bottom: 0.2rem;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.previewAd {
  background-color: #fafafa;
  color: #4d4f5c;
}
