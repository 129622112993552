.generalWrapper {
  width: 100%;
  min-width: 300px;
}

.generalContentWrapper {
  width: 100%;
  margin-top: 10px;
  /* max-height: 550px; */
}

.statusRadioButton {
  flex-direction: row !important;
  align-items: center;
}

.radioButtonLabels {
  width: 75px;
  text-align: center;
}

.autoManualPricing :global(.ant-form-item-label > label) {
  margin-left: 30px;
}

.datepickerStart,
.datepickerEnd {
  width: 50%;
  display: inline-block;
}

.datepickerStart :global(.ant-col.ant-form-item-control) {
  margin-right: 10px;
}

.datepickerStart :global(.ant-picker),
.datepickerEnd :global(.ant-picker) {
  width: 100%;
}

.statusInfo {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.statusInfo>p {
  margin: 0;
  color: #000000d9;
  padding-right: 0.5rem;
}

.manualValueInput {
  display: inline-block;
  width: 150px;
  position: absolute;
  margin-top: 30px;
  margin-left: 10px;
}

.formLabel {
  color: #4d4f5c;
  display: inline-block;
  padding-bottom: 0;
  font-size: 12px;
  line-height: 30px;
  margin-right: 10px;
}

.useSuggestedBid {
  min-height: 60px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.budgetAndPeriod {
  min-height: 93px;
  display: flex;
  justify-content: space-between;
}

.campaignBid :global(.ant-input-number) {
  max-width: 100px;
  width: 100px;
  margin-right: 8px;
}

.campaignBid :global(.ant-form-item-explain-error) {
  position: absolute;
  width: 350px;
  margin-top: 4px;
}

/* .campaignBid:global(.ant-form-item-with-help) {
  white-space: nowrap;
  max-width: 100px;
  width: 100px;
  margin-right: 8px;
} */
.campaignBudget {
  display: inline-block;
  width: 48%;
  /* margin-right: 7%; */
}

.campaignBudget input {
  min-height: 32px;
}

.campaignBudgetPeriod {
  display: inline-block;
  width: 48%;
}

.proceedButton {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin-top: 20px;
  border-radius: 4px;
  background-color: #4090f7;
}

@media (max-width: 1100px) {
  .generalWrapper {
    width: 95%;
    margin: 0 auto;
  }
}

@media (max-width: 760px) {
  .useSuggestedBid {
    flex-direction: column;
    margin-bottom: 24px;
  }

  .useSuggestedBid .campaignBid {
    margin-bottom: 16px;
  }
}

/* .campaignTypeInput,
  .accName {
    width: 50%;
    display: inline-block;
  }
  
  .accName :global(.ant-col.ant-form-item-control) {
    margin-right: 10px;
  }
  .campaignTypeInput :global(.ant-form-item-control-input-content) {
    display: flex;
  } */