.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.3rem;
}

.titleContainer>h4 {
  color: #43425d;
  font-size: 1.4rem;
  margin: 0;
}

.mainContainer table thead th {
  background-color: #fff;
  color: #707070;
  font-size: 0.9rem;
}

.mainContainer table tbody {
  font-size: 0.85rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #808080 !important;
}

.mainContainer :global(.ant-radio-group) {
  margin-bottom: 2rem;
}

.mainContainer :global(.ant-radio-group .ant-radio-button-wrapper) {
  width: 180px;
  border-radius: 5px;
  margin-right: 1rem;
  border-width: 1px;
}

.mainContainer :global(.ant-radio-button-wrapper:not(:first-child):before) {
  display: none;
}

.campaignsWrapper :global(.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder > td) {
  border-bottom: none;
}

.mainContainer :global(.ant-table-row) {
  background-color: #fff;
}

.mainContainer :global(.ant-table-footer) {
  background-color: #fff;
  padding: 16px 0;
}

.globalBlacklistContainer {
  color: #43425d;
  padding: 20px 30px;
  box-shadow: 0px 2px 6px #00000012;
  margin-bottom: 2rem;
}

.globalBlacklistContainer h3 {
  color: #43425d;
  font-size: 18px;
}

.userListsContainer {
  color: #43425d;
  padding: 20px 30px;
  box-shadow: 0px 2px 6px #00000012;
}

.userListsContainer h3 {
  color: #43425d;
  font-size: 18px;
}

.newListButton,
.newListButton:global(.ant-btn-primary:focus),
.newListButton:global(.ant-btn-primary:hover) {
  background-color: #f9ae3b;
  border: 1px solid #f9ae3b;
  border-radius: 5px;
  margin-bottom: 2rem;
}

.plusIcon {
  font-size: 11px;
}

/* ACTION TABLE COLUMN */

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions>button {
  cursor: pointer;
  margin-right: 6px;
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
}

.actions>span {
  margin-right: 6px;
}

.actions button:last-child {
  margin-right: 0;
}

.actions :global(.ant-popover-inner-content) {
  padding: 5px 8px !important;
}

.overflowName {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listFormDrawer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.listFormDrawer :global(.ant-row),
.listFormDrawer :global(.ant-row .ant-col) {
  height: 100%;
}

.listFormDrawer h3 {
  font-size: 18px;
  margin-bottom: 1.5rem;
  color: #43425d;
}

.listFormDrawer :global(.ant-drawer-header) {
  padding: 30px 24px;
  box-shadow: 0px 2px 5px #0000000d;
}

.listFormDrawer :global(.ant-drawer-footer) {
  box-shadow: 0px -2px 5px #0000000d;
  padding: 24px 20px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.listFormDrawer :global(.ant-drawer-footer) button {
  min-width: 180px;
  border-radius: 3px;
}

.listFormDrawer :global(.ant-drawer-footer) button:first-child {
  margin-right: 1rem;
}

.listFormDrawer .drawerTitle {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  color: #43425d;
}

.listFormDrawer .listFormWrapper {
  border: 1px solid #eeeeee;
  border-radius: 3px;
  padding: 18px 20px;
  height: calc(100% - 60px);
  overflow: scroll;
}

.listFormDrawer .campaignsWrapper {
  border: 1px solid #eeeeee;
  height: calc(100% - 60px);
  border-radius: 3px;
  overflow: scroll;
}

.listFormWrapper h4 {
  font-weight: 600;
}

.listFormWrapper :global(.ant-form-item-label) label {
  font-weight: 600;
}

.listFormWrapper .inputWrapper {
  border: 1px solid #eeeeee;
  border-radius: 3px;
  padding: 16px;
}

.listFormWrapper .inputWrapper .clearBtn {
  margin-left: .5rem;
}

.copyBtn {
  padding-right: 5px;
  padding-left: 5px;
}

.listFormWrapper .buttonsWrapper {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}

.listFormWrapper .subidInput :global(.ant-row) {
  width: 100%;
  margin: auto;
}

.campaignName {
  margin-bottom: 0.3rem;
  text-align: left;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #0071bd;
  font-weight: 600;
  cursor: pointer;
}

.campaignIdAndType {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.campaignIdAndType span {
  margin-right: 0.8rem;
  color: #4d4f5c;
}

.fullWidthDrawer {
  width: 100% !important;
}

@media (max-width: 1440px) {
  .listFormWrapper :global(.ant-select-selection-overflow) {
    max-height: 250px;
    overflow: scroll;
  }
}

@media (max-width: 767px) {

  .listFormDrawer :global(.ant-row),
  .listFormDrawer :global(.ant-row .ant-col) {
    height: auto;
  }

  .listFormDrawer :global(.ant-row .ant-col):first-child {
    margin-bottom: 1.5rem;
  }

  .listFormDrawer .listFormWrapper {
    height: auto;
  }

  .listFormDrawer .campaignsWrapper {
    height: 320px;
  }
}